import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { AiTwotoneDelete } from "react-icons/ai";
import { ReceiptContext } from "../../libs/ReceiptContextLib";

const RemoveReceiptLine = (props) => {
  const Receipt = useContext(ReceiptContext),
    ID = props.ID;

  const handleRemoveElement = (e) => {
    const ContentListCopy = Array.from(Receipt.RowContentList);
    var a = ContentListCopy.filter((obj) => {
      return obj.ID !== props.Row.ID;
    });

    Receipt.setRowContentList(a);
  };

  const btnRemove = () => {
    return (
      <div className="btnRemove right">
        <Button block="true" variant="primary" onClick={handleRemoveElement}>
          <AiTwotoneDelete />
        </Button>
      </div>
    );
  };

  return <td role="cell">{ID > 1 ? btnRemove() : null}</td>;
};

export default RemoveReceiptLine;
