import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { AppContext } from "../../libs/contextLib";
import withRouter from "../../libs/withRouter";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FaSignInAlt } from "react-icons/fa";
import * as yup from "yup";
import FormInputBox from "../Form/FormInputbox";
import { FaArrowCircleLeft } from "react-icons/fa";
import { trackPromise } from "react-promise-tracker";
import {
  passRegex,
  PasswordRequirements,
  emailRegex,
  emailRegexMessage,
} from "../../libs/Variables";

const schemaEmailAddress = yup
    .string()
    .required("Email Address is required")
    .matches(emailRegex, emailRegexMessage)
    .max(320, "Email Address must be less than 320 characters"),
  schemaConfirmationCode = yup
    .string()
    .required("ConfirmationCode is required")
    .matches(/^[0-9]*$/, "ConfirmationCode is invalid.")
    .max(6, "ConfirmationCode must be less than 6 characters"),
  schemaPassword = yup
    .string()
    .required("Password is required")
    .min(12, "Password does not meet requirements")
    .max(160, "Password does not meet requirements")
    .matches(passRegex, "Password does not meet requirements");

let firstPass = yup.object().shape({
    EmailAddress: schemaEmailAddress,
  }),
  secondPass = yup.object().shape({
    EmailAddress: schemaEmailAddress,
    ConfirmationCode: schemaConfirmationCode,
    Password: schemaPassword,
  });

const LabelColumnAmount = 3,
  InputColumnAmount = 6;

class ForgotPassword extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      EmailAddress: "",
      ForgotPasswordSent: false,
      validated: false,
      Password: "",
      errorList: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCallback = (name, theData) => {
    this.setState(
      {
        [name]: theData,
      },
      () => {}
    );
  };

  async validateForm(schema, validateString) {
    var validateResult = await schema
      .validate(validateString, { abortEarly: false })
      .then(() => {
        return true;
      })
      .catch((e) => {
        var message = {};

        e.inner.forEach((e) => {
          message[e.path] = e.message;
        });
        return message;
      });

    if (validateResult === true) {
      this.setState({ validated: true, errorList: "" }, () => {
        return true;
      });
    } else {
      this.setState({ validated: false, errorList: validateResult }, () => {});
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    let validateString = {
      EmailAddress: this.state.EmailAddress,
    };
    let schemaLogin = firstPass;
    if (this.state.ForgotPasswordSent) {
      validateString = {
        EmailAddress: this.state.EmailAddress,
        Password: this.state.Password,
        ConfirmationCode: this.state.ConfirmationCode,
      };
      schemaLogin = secondPass;
    }

    this.validateForm(schemaLogin, validateString).then(async () => {
      if (this.state.validated) {
        if (!this.state.ForgotPasswordSent) {
          await trackPromise(
            Auth.forgotPassword(this.state.EmailAddress)
              .then((data) => {
                this.setState({ ForgotPasswordSent: true, validated: false });
              })
              .catch((e) => {
                this.setState({ authErrorList: e.message });
              })
          );
        } else {
          await trackPromise(
            Auth.forgotPasswordSubmit(
              this.state.EmailAddress,
              this.state.ConfirmationCode,
              this.state.Password
            )
              .then((data) => {
                this.props.navigate("/Login", {
                  state: {
                    PasswordResetSuccess: true,
                  },
                });
              })
              .catch((e) => {
                this.setState({ authErrorList: e.message });
              })
          );
        }
      }
    });
  }
  render() {
    return (
      <>
        <h1>Reset Password</h1>
        <Row>
          <Col>
            <div className="Link" onClick={() => this.props.navigate(-1)}>
              <FaArrowCircleLeft /> Cancel Password Reset
            </div>
          </Col>
        </Row>
        <div className="Login">
          {!this.state.ForgotPasswordSent ? (
            <Row>
              <Col sm={InputColumnAmount}>
                <p>
                  Please enter your email address to begin the reset password
                  process
                </p>
              </Col>
            </Row>
          ) : null}
          <Form onSubmit={this.handleSubmit}>
            <FormInputBox
              type="text"
              Label="Email Address"
              name="EmailAddress"
              placeholder={this.state.EmailAddress}
              value={this.handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={this.state.errorList}
              schema={schemaEmailAddress}
            />
            {this.state.ForgotPasswordSent ? (
              <>
                <Row>
                  <Col sm={InputColumnAmount}>
                    <p>Please enter the code that you were emailed.</p>
                  </Col>
                </Row>
                <FormInputBox
                  type="text"
                  Label="Confirmation Code"
                  name="ConfirmationCode"
                  placeholder={this.state.ConfirmationCode}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  errorList={this.state.errorList}
                  schema={schemaConfirmationCode}
                />

                <Row>
                  <Col sm={InputColumnAmount}>
                    <p>Please enter your new password.</p>
                    {PasswordRequirements()}
                  </Col>
                </Row>

                <FormInputBox
                  type="password"
                  Label="New Password"
                  name="Password"
                  placeholder={this.state.Password}
                  value={this.handleCallback}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  errorList={this.state.errorList}
                  schema={schemaPassword}
                />
              </>
            ) : null}
            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={3} className="IconBtn">
                <Button variant="primary" type="submit">
                  <FaSignInAlt /> Submit
                </Button>
                {this.state.authErrorList ? (
                  <div className="invalid-tooltip">
                    {this.state.authErrorList}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

ForgotPassword.contextType = AppContext;

export default withRouter(ForgotPassword);
