import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  FaSave,
  FaTimesCircle,
  FaAngleDoubleUp,
  FaAngleDoubleDown,
} from "react-icons/fa";
import { AppContext } from "../../libs/contextLib";
import { ReceiptContext } from "../../libs/ReceiptContextLib";
import apiError from "../../libs/apiError";
import { trackPromise } from "react-promise-tracker";
import OutputMessage from "../OutputMessage";
import GetDatetime from "../../libs/GetDatetime";
import FormRadio from "../Form/FormRadio";
import FormInputBox from "../Form/FormInputbox";
import FormDropdown from "../Form/FormDropdown";
import FormDatePicker from "../Form/FormDatePicker";
import { handleValidation } from "../handleValidation";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";
import ReceiptFormLines from "./ReceiptFormLines";
import { DefaultLineItem } from "./DefaultLineItem";
import AddAttachment from "./AddAttachment";

const NewReceiptList = (props) => {
  const context = useContext(AppContext);
  const Receipt = useContext(ReceiptContext);
  const location = useLocation();

  const [errorList, setErrorList] = useState([]);
  const [ValidationErrorList, setValidationErrorList] = useState([]);
  const [PostingValidationList, setPostingValidationList] = useState([]);
  const [SettingsData, setSettingsData] = useState(props.SettingsData);
  const [PostFeedback, setPostFeedback] = useState([]);
  const [NotEditable /*setNotEditable*/] = useState(false);

  const BlankState = {
    BankAccount: SettingsData.defaultBank,
    JournalType: SettingsData.defaultJournalType,
    PostingDate: GetDatetime(true),
    Description: "",
    Location: SettingsData.defaultLocation,
    Reference: "",
    Comment: "",
    AttachmentList: [],
    DocName: "",
    Amount: parseFloat(0).toFixed(2),
  };
  const [BankAccount, setBankAccount] = useState(BlankState.BankAccount);
  const [JournalType, setJournalType] = useState(BlankState.JournalType);
  const [PostingDate, setPostingDate] = useState(BlankState.PostingDate);
  const [Description, setDescription] = useState(BlankState.Description);
  const [Location, setLocation] = useState(BlankState.Location);
  const [Reference, setReference] = useState(BlankState.Reference);
  const [Comment, setComment] = useState(BlankState.Comment);
  const [Amount, setAmount] = useState(BlankState.Amount);

  const [TaxImplication, setTaxImplication] = useState();
  const [BankAccountList] = useState(SettingsData.bankAccountList);
  const JournalTypeLists = SettingsData.journalTypeLists;
  const TaxImplicationList = SettingsData.taxImplicationList;
  const [paymentsType, setPaymentsType] = useState();
  const [receiptsType, setReceiptsType] = useState();
  const ClassMandatory = SettingsData.classMandatory;
  const DepartmentMandatory = SettingsData.departmentMandatory;
  const MemoMandatory = SettingsData.memoMandatory;
  const UseAttachments = SettingsData.useAttachments;
  const [AttachmentList, setAttachmentList] = useState([]);
  const [DocName, setDocName] = useState();

  const [ShowCollapse, setShowCollapse] = useState(true);

  const DescriptionSchema = yup
      .string()
      .required("Required")
      .nullable()
      .matches(inputRegex, inputRegexMessage)
      .max(1000, "Must be less than 1000 characters"),
    ReferenceSchema = yup
      .string()
      .required("Required")
      .nullable()
      .matches(inputRegex, inputRegexMessage)
      .max(40, "Must be less than 40 characters"),
    DocNameSchema = yup
      .string()
      .required("Required")
      .nullable()
      .matches(inputRegex, inputRegexMessage)
      .max(100, "Must be less than 100 characters"),
    CommentSchema = yup
      .string()
      .nullable()
      .matches(inputRegex, inputRegexMessage)
      .max(1000, "Must be less than 1000 characters"),
    AmountSchema = yup
      .string()
      .required("Required")
      .matches(
        /^(\+)?([0-9]+(\.[0-9]{1,2})?)$/,
        "Must be a positive decimal correct to 2 places."
      );

  const params = location.state;

  const TaxImplicationListIndex = useCallback(
    (t) => TaxImplicationList.findIndex((obj) => obj.name === t),
    [TaxImplicationList]
  );

  const mounted = useRef();
  useEffect(() => {
    try {
      if (params !== undefined && params) {
        setPostFeedback(params.PostFeedback);
      }
      if (!mounted.current) {
        var index = JournalTypeLists.findIndex((obj) => obj.id === JournalType);
        setPaymentsType(JournalTypeLists[index].paymentsType);
        setReceiptsType(JournalTypeLists[index].receiptsType);

        // do componentDidMount logic
        mounted.current = true;

        if (JournalTypeLists[index].receiptsType) {
          if (TaxImplicationListIndex("Sales Tax") === -1) {
            throw new Error("Sales");
          }
          setTaxImplication(
            TaxImplicationList[TaxImplicationListIndex("Sales Tax")].id
          );
        } else if (JournalTypeLists[index].paymentsType) {
          if (TaxImplicationListIndex("Purchase Tax") === -1) {
            throw new Error("Purchase");
          }
          setTaxImplication(
            TaxImplicationList[TaxImplicationListIndex("Purchase Tax")].id
          );
        }
      } else {
        // do componentDidUpdate logic
      }
    } catch (e) {
      var error = e;
      if (e.message.includes("Cannot read properties of undefined"))
        error =
          "Cannot set TaxImplication, check defaults set in User Settings.";
      setErrorList((errorList) => [...errorList, error]);
    }
  }, [
    JournalType,
    JournalTypeLists,
    TaxImplication,
    TaxImplicationList,
    TaxImplicationListIndex,
    params,
  ]);

  const resetForm = useCallback(() => {
    setPostingValidationList([]);
    setValidationErrorList([]);
    setErrorList([]);
    Receipt.setReceiptHeaderItems("");
    Receipt.setRowContentList([]);
    setSettingsData(props.SettingsData);
    setShowCollapse(true);

    setBankAccount(BlankState.BankAccount);
    setJournalType(BlankState.JournalType);
    setPostingDate(BlankState.PostingDate);
    setDescription(BlankState.Description);
    setLocation(BlankState.Location);
    setReference(BlankState.Reference);
    setComment(BlankState.Comment);
    setAmount(BlankState.Amount);
    setAttachmentList(BlankState.AttachmentList);
    setDocName(BlankState.DocName);
  }, [
    BlankState.Amount,
    BlankState.AttachmentList,
    BlankState.BankAccount,
    BlankState.Comment,
    BlankState.Description,
    BlankState.DocName,
    BlankState.JournalType,
    BlankState.Location,
    BlankState.PostingDate,
    BlankState.Reference,
    Receipt,
    props.SettingsData,
  ]);

  const handleFormReset = (e) => {
    e.preventDefault();
    resetForm();
  };

  const validateForm = () => {
    setValidationErrorList([]);
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "Description",
          data: Description,
          schema: DescriptionSchema,
        },
        {
          name: "Reference",
          data: Reference,
          schema: ReferenceSchema,
        },
        {
          name: "Comment",
          data: Comment,
          schema: CommentSchema,
        },
        {
          name: "Amount",
          data: Amount,
          schema: AmountSchema,
        },
      ];
      if (UseAttachments) {
        items.push({
          name: "Attachment Name",
          data: DocName,
          schema: DocNameSchema,
        });
      }
      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            setValidationErrorList((errorList) => [...errorList, e]);
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();

    Receipt.setReceiptHeaderItems("");
    Receipt.setRowContentList([]);
    validateForm()
      .then(async (response) => {
        var TaxType = "None";
        if (paymentsType) {
          TaxType = "Purchase";
        }
        if (receiptsType) {
          TaxType = "Sale";
        }
        if (TaxImplication === 1) {
          TaxType = "None";
        }

        await trackPromise(
          Promise.all([
            axios
              .get(
                "/api/ReceiptLists/GetReceiptLineContent/" +
                  SettingsData.SiteID +
                  "/" +
                  TaxType
              )
              .catch((e) => {
                apiError("apiUrl Get: ", e);
                var message = apiError("ReceiptListLine: ", e);
                setErrorList((errorList) => [...errorList, message]);
              }),
          ])
            .then((responses) => {
              if (responses[0] && responses[0] !== undefined) {
                let Header = {
                  BankAccount,
                  JournalType,
                  paymentsType,
                  receiptsType,
                  PostingDate,
                  Description,
                  Location,
                  Reference,
                  Comment,
                  Amount,
                  TaxImplication,
                  TaxType,
                  DocName,
                  AttachmentList,
                  SiteID: SettingsData.SiteID,
                };
                Receipt.setReceiptHeaderItems(Header);
                var LineContent = responses[0].data[0];
                Receipt.setLineContent(LineContent);
                var DefaultItemList = DefaultLineItem(1, Header, LineContent);
                Receipt.setRowContentList([DefaultItemList]);

                var TotalIncTax =
                  parseFloat(Amount) +
                  (parseFloat(Amount) * parseFloat(DefaultItemList.Rate)) / 100;

                Receipt.setGrandTotalDebit(
                  receiptsType
                    ? TotalIncTax.toFixed(2)
                    : parseFloat(0).toFixed(2)
                );
                Receipt.setGrandTotalCredit(
                  paymentsType
                    ? TotalIncTax.toFixed(2)
                    : parseFloat(0).toFixed(2)
                );
                setShowCollapse(!ShowCollapse);
              } else {
                throw new Error("No Receipt List Line Data Found");
              }
            })
            .catch((e) => {
              var message = apiError("ReceiptListLine: ", e);
              setErrorList((errorList) => [...errorList, message]);
              return { success: false };
            })
        );
      })
      .catch((e) => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidationErrorList([]);
    setPostingValidationList([]);
    var ErrorReasonList = [],
      RowRemovalList = [];
    try {
      if (
        Receipt.GrandTotalDebit === "0.00" &&
        Receipt.GrandTotalCredit === "0.00"
      ) {
        throw new Error("Total Debit and Credit both equal 0.");
      }
      if (Receipt.GrandTotalDebit !== Receipt.GrandTotalCredit) {
        throw new Error(
          "Receipt Imbalanced - Total Debit and Credit do not match."
        );
      }

      Receipt.RowContentList.forEach((element, i) => {
        const index = Receipt.RowContentList.findIndex(
          (obj) => obj.ID === element.ID
        );
        var RowNumber = index + 1;
        var ErrorReason = [];

        if (element.Debit === "0.00" && element.Credit === "0.00") {
          RowRemovalList.push(element.ID);
          // ErrorReason.push(
          //   `Debit and Credit have not been set on row ${RowNumber}.`
          // );
          return;
        }

        if (i !== 0) {
          if (element.ItemCode === 0) {
            ErrorReason.push(`ItemCode has not been set on row ${RowNumber}.`);
          }
          if (ClassMandatory) {
            if (element.Class === 0) {
              ErrorReason.push(`Class has not been set on row ${RowNumber}.`);
            }
          }
          if (DepartmentMandatory) {
            if (element.Department === 0) {
              ErrorReason.push(
                `Department has not been set on row ${RowNumber}.`
              );
            }
          }
          //only do the check if Tax Implication is set as 'None'
          if (TaxImplication !== 1 && element.TaxDetails === 0) {
            ErrorReason.push(
              `Tax Details have not been set on row ${RowNumber}.`
            );
          }
        }
        if (MemoMandatory) {
          if (element.Memo.trim() === "") {
            ErrorReason.push(`Memo has not been set on row ${RowNumber}.`);
          }
        }
        // if (ErrorReason.length >= 5) {
        //   RowRemovalList.push(element.ID);
        // }
        if (ErrorReason.length >= 1 /* && ErrorReason.length <= 4*/) {
          ErrorReasonList.push(ErrorReason);
        }
      });

      if (RowRemovalList.length >= 1) {
        Receipt.RowContentList = Receipt.RowContentList.filter(
          (item) => !RowRemovalList.includes(item.ID)
        );
      }

      if (ErrorReasonList.length >= 1) {
        ErrorReasonList.forEach((element) => {
          element.forEach((row) => {
            setPostingValidationList((errorList) => [...errorList, row]);
          });
        });
      } else {
        doSubmit();
      }
    } catch (error) {
      setPostingValidationList((errorList) => [...errorList, error.message]);
    }
  };

  const doSubmit = async () => {
    try {
      var outputJSON = Receipt.ReceiptHeaderItems;
      outputJSON["ReceiptListLines"] = Receipt.RowContentList;
      outputJSON["CreatedBy"] = context.usr;

      // console.log(outputJSON);
      const postAPI = "api/ReceiptLists/";
      await trackPromise(
        axios
          .post(postAPI, outputJSON)
          .then(() => {
            resetForm();
            setPostFeedback(["Receipt successfully posted to Intacct."]);
          })
          .catch((e) => {
            var message = apiError("PostAPI: ", e);
            setErrorList((errorList) => [...errorList, message]);
          })
      );
    } catch (e) {
      setErrorList((errorList) => [...errorList, e.message]);
    }
  };

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name, theData);
    var pattErrorList = new RegExp("errorList"),
      pattJournalType = new RegExp("JournalType"),
      pattTaxImplication = new RegExp("TaxImplication"),
      pattClearErrorList = new RegExp("ClearErrorList");
    if (pattErrorList.test(name)) {
      setValidationErrorList((errorList) => [...errorList, theData]);
    } else if (pattClearErrorList.test(name)) {
      if (name === "ClearValidationErrorList") {
        setValidationErrorList([]);
        setPostingValidationList([]);
      } else {
        setErrorList(errorList.filter((item) => item !== theData));
      }
    } else if (pattJournalType.test(name)) {
      theData = parseInt(theData);
      var index = JournalTypeLists.findIndex((obj) => obj.id === theData);
      setPaymentsType(JournalTypeLists[index].paymentsType);
      setReceiptsType(JournalTypeLists[index].receiptsType);

      if (JournalTypeLists[index].receiptsType) {
        if (TaxImplicationListIndex("Sales Tax") === -1) {
          throw new Error("Sales");
        }
        setTaxImplication(
          TaxImplicationList[TaxImplicationListIndex("Sales Tax")].id
        );
      } else if (JournalTypeLists[index].paymentsType) {
        if (TaxImplicationListIndex("Purchase Tax") === -1) {
          throw new Error("Purchase");
        }
        setTaxImplication(
          TaxImplicationList[TaxImplicationListIndex("Purchase Tax")].id
        );
      } else {
        if (TaxImplicationListIndex("None") === -1) {
          throw new Error("None");
        }
        setTaxImplication(
          TaxImplicationList[TaxImplicationListIndex("None")].id
        );
      }

      setter(theData);
    } else if (pattTaxImplication.test(name)) {
      switch (theData) {
        case 1: //None
          index = JournalTypeLists.findIndex((obj) => obj.id === JournalType);
          setPaymentsType(JournalTypeLists[index].paymentsType);
          setReceiptsType(JournalTypeLists[index].receiptsType);
          break;
        case 2: //Sales
          setPaymentsType(false);
          setReceiptsType(true);
          break;
        case 3: //Purchese
          setPaymentsType(true);
          setReceiptsType(false);
          break;

        default:
          break;
      }
      setter(theData);
    } else {
      setter(theData);
    }
  };

  return (
    <div className="NewReceiptList">
      {SettingsData ? (
        <>
          <OutputMessage
            errorList={errorList}
            ClearErrorList={handleCallback()}
            PostFeedback={PostFeedback}
          />
          <div className="detailsContent">
            {Object.keys(Receipt.ReceiptHeaderItems).length >= 1 ? (
              <div className="TooltipActionButton">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="CommitInfoTooltip">
                      {ShowCollapse ? "Hide Header" : "Show Header"}
                    </Tooltip>
                  }
                >
                  <Button
                    aria-expanded={ShowCollapse}
                    onClick={() => {
                      setShowCollapse(!ShowCollapse);
                    }}
                  >
                    {ShowCollapse ? (
                      <>
                        <FaAngleDoubleUp /> Hide Header
                      </>
                    ) : (
                      <>
                        <FaAngleDoubleDown />
                        Show Header
                      </>
                    )}
                  </Button>
                </OverlayTrigger>
              </div>
            ) : null}
            <Form id="NewReceiptListForm" onReset={handleFormReset}>
              <Collapse in={ShowCollapse}>
                <div>
                  <FormDropdown
                    id="Location"
                    Label="Location"
                    name="Location"
                    placeholder={Location}
                    itemContent={SettingsData.locationList}
                    disabled={NotEditable}
                    value={handleCallback(setLocation)}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    errorList={handleCallback(setValidationErrorList)}
                  />
                  <FormDropdown
                    id="BankAccount"
                    Label="Bank Account"
                    name="BankAccount"
                    placeholder={BankAccount}
                    itemContent={BankAccountList}
                    disabled={NotEditable}
                    value={handleCallback(setBankAccount)}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    errorList={handleCallback(setValidationErrorList)}
                  />
                  <FormDropdown
                    id="JournalType"
                    Label="Journal Type"
                    name="JournalType"
                    placeholder={JournalType}
                    itemContent={JournalTypeLists}
                    disabled={NotEditable}
                    value={handleCallback(setJournalType)}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    errorList={handleCallback(setValidationErrorList)}
                  />
                  <FormDatePicker
                    Label="Posting Date"
                    name="PostingDate"
                    placeholder={PostingDate}
                    value={handleCallback(setPostingDate)}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={2}
                    disabled={NotEditable}
                    errorList={handleCallback(setValidationErrorList)}
                    ShowBlank={false}
                  />
                  <FormInputBox
                    type="text"
                    Label="Description"
                    name="Description"
                    placeholder={Description}
                    value={handleCallback(setDescription)}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    disabled={NotEditable}
                    errorList={handleCallback(setValidationErrorList)}
                    schema={DescriptionSchema}
                  />
                  <FormInputBox
                    type="text"
                    Label="Reference"
                    name="Reference"
                    placeholder={Reference}
                    value={handleCallback(setReference)}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    disabled={NotEditable}
                    errorList={handleCallback(setValidationErrorList)}
                    schema={ReferenceSchema}
                  />
                  <FormInputBox
                    type="text"
                    Label="Comment"
                    name="Comment"
                    placeholder={Comment}
                    value={handleCallback(setComment)}
                    Instructions={"Add your comment to the transaction history"}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    disabled={NotEditable}
                    errorList={handleCallback(setValidationErrorList)}
                    schema={CommentSchema}
                  />
                  <FormInputBox
                    type="text"
                    IsCurrency={true}
                    Label="Amount"
                    name="Amount"
                    placeholder={Amount}
                    value={handleCallback(setAmount)}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    disabled={NotEditable}
                    errorList={handleCallback(setValidationErrorList)}
                    schema={AmountSchema}
                  />

                  {UseAttachments ? (
                    <>
                      <FormInputBox
                        type="text"
                        Label="Attachment Name"
                        name="DocName"
                        placeholder={DocName}
                        value={handleCallback(setDocName)}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                        disabled={NotEditable}
                        errorList={handleCallback(setValidationErrorList)}
                        schema={DocNameSchema}
                      />
                      <AddAttachment
                        Label="Add Attachment"
                        name="AttachmentList"
                        value={handleCallback(setAttachmentList)}
                        AttachmentList={AttachmentList}
                        LabelColumnAmount={LabelColumnAmount}
                        InputColumnAmount={InputColumnAmount}
                        errorList={handleCallback(setValidationErrorList)}
                      />
                    </>
                  ) : null}

                  <FormRadio
                    Label="Tax Implication"
                    name="TaxImplication"
                    id="TaxImplication"
                    valueList={TaxImplicationList}
                    initialState={TaxImplication}
                    value={handleCallback(setTaxImplication)}
                    LabelColumnAmount={LabelColumnAmount}
                    InputColumnAmount={InputColumnAmount}
                    disabled={NotEditable}
                    errorList={handleCallback(setValidationErrorList)}
                  />
                  {!NotEditable ? (
                    <div className="FormGroup row">
                      <Col sm={LabelColumnAmount}></Col>
                      <Col sm={4} className="IconBtn">
                        {ValidationErrorList.length >= 1 ? (
                          <ValidationError
                            errorListState={ValidationErrorList}
                            ClearErrorList={handleCallback(
                              setValidationErrorList
                            )}
                          />
                        ) : null}
                        <Button variant="primary" onClick={handleContinue}>
                          <FaSave />

                          {Object.keys(Receipt.ReceiptHeaderItems).length === 0
                            ? "Generate"
                            : "Update"}
                        </Button>
                        {/* {NoUpdateRequired ? DataNotChanged : null} */}
                        {Object.keys(Receipt.ReceiptHeaderItems).length <= 0 ? (
                          <>
                            <Button variant="primary" onClick={handleFormReset}>
                              <FaTimesCircle /> Reset
                            </Button>
                          </>
                        ) : null}
                      </Col>
                    </div>
                  ) : null}
                </div>
              </Collapse>

              {Object.keys(Receipt.ReceiptHeaderItems).length >= 1 ? (
                <>
                  <ReceiptFormLines ShowCollapse={ShowCollapse} />

                  <div className="container FormSave">
                    <Row>
                      <Col sm={LabelColumnAmount}></Col>
                      <Col sm={8} className="IconBtn">
                        {PostingValidationList.length >= 1 ? (
                          <ValidationError
                            errorListState={PostingValidationList}
                            ClearErrorList={handleCallback(
                              setPostingValidationList
                            )}
                          />
                        ) : null}
                        <Button
                          variant="primary"
                          type="button"
                          onClick={handleSubmit}
                          name="Submit"
                        >
                          <FaSave /> Post
                        </Button>
                        <Button variant="primary" onClick={handleFormReset}>
                          <FaTimesCircle /> Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : null}
            </Form>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default NewReceiptList;
