import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const FormLabelbox = (props) => {
  const Box = () => {
    return (
      <span className="form-control-plaintext">{props.placeholder || ""}</span>
    );
  };
  return !props.hidden ? (
    <Form.Group className={"FormGroup"} as={Row} controlId={props.name}>
      {!props.Label ? (
        Box()
      ) : (
        <>
          <Form.Label column sm={props.LabelColumnAmount}>
            {props.Label}
          </Form.Label>
          <Col sm={props.InputColumnAmount}>{Box()}</Col>
        </>
      )}
    </Form.Group>
  ) : null;
};

export default FormLabelbox;
