import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../libs/contextLib";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  FaArrowCircleLeft,
  FaRegEdit,
  FaTimesCircle,
  FaSave,
} from "react-icons/fa";
import { trackPromise } from "react-promise-tracker";
import * as yup from "yup";
import axios from "axios";
import apiError from "../../libs/apiError";
import FormLabelbox from "../Form/FormLabelbox";
import FormInputBox from "../Form/FormInputbox";
import FormCheck from "../Form/FormCheck";
import FormContainer from "../Form/FormContainer";
import UserListTable from "./UserListComponents/UserListTable";
import GetDatetime from "../../libs/GetDatetime";
import OutputMessage from "../OutputMessage";
import { handleValidation } from "../handleValidation";
import {
  LabelColumnAmount,
  InputColumnAmount,
  DataNotChanged,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";
import {
  CognitoIdentityProviderClient,
  AdminUpdateUserAttributesCommand,
  // AdminSetUserMFAPreferenceCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { Auth } from "aws-amplify";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

const FirstNameSchema = yup
    .string()
    .required("Required")
    .matches(inputRegex, inputRegexMessage)
    .max(50, "Must be less than 50 characters"),
  LastNameSchema = yup
    .string()
    .required("Required")
    .matches(inputRegex, inputRegexMessage)
    .max(50, "Must be less than 50 characters"),
  schemaPhoneNumber = yup
    .string()
    .required("Required")
    .nullable()
    .matches(
      /^(\+)[0-9]*$/,
      "Must be all numbers and begin with a Country Code e.g. +44"
    )
    .max(50, "Must be less than 50 characters");

const UserSettings = () => {
  const context = useContext(AppContext);
  const location = useLocation();

  const params = location.state;
  const [Username, setUsername] = useState("");

  const [notEditable, setNotEditable] = useState(true);
  const [editVisible, setEditVisible] = useState(true);
  const [reloadPage, setReloadPage] = useState(false);
  const [FirstName, setFirstName] = useState("");
  const [IsActive, setIsActive] = useState(true);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [CanViewAllTransactions, setCanViewAllTransactions] = useState(false);
  const [AllTransactionLocations, setAllTransactionLocations] = useState(false);
  const [LastName, setLastName] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [errorList, setErrorList] = useState([]);

  const [ValidationErrorList, setValidationErrorList] = useState([]);

  const [SiteID, setSiteID] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState("");
  const [AddedSuccess, setAddedSuccess] = useState("");

  const [DataUpdated, setDataUpdated] = useState(false);
  const [NoUpdateRequired, setNoUpdateRequired] = useState(false);
  const [PlaceholderListFilled, setPlaceholderListFilled] = useState(false);
  const [SiteSettingsID, setSiteSettingsID] = useState();
  const [UserListID, setUserListID] = useState();
  const [UserSiteID, setUserSiteID] = useState();

  const [BankAccountList, setBankAccountList] = useState();
  const [JournalTypeLists, setJournalTypeLists] = useState();
  const [LocationList, setLocationList] = useState();

  if (!SiteID) {
    setSiteID(sessionStorage.getItem("siteID"));
  }

  const loadData = useCallback(async () => {
    if (params !== undefined && params) {
      setAddedSuccess(params.NewUser ? true : false);
      setUsername(params.Username);
    } else {
      setUsername(context.usr);
    }
    setNotEditable(true);
    setEditVisible(true);

    if (SiteID && Username) {
      await trackPromise(
        Promise.all([
          axios
            .get("/api/UserSites/GetUserSettings/" + SiteID + "/" + Username)
            .catch((e) => {
              var message = apiError("API User Settings Get: ", e);
              setErrorList((errorList) => [...errorList, message]);
            }),
        ]).then((responses) => {
          let placeholderArr;
          if (responses[0] && responses[0] !== undefined) {
            placeholderArr = responses[0].data[responses[0].data.length - 1];
          } else {
            throw new Error("Problem Fetching User Settings.");
          }
          setFirstName(placeholderArr.firstName);
          setIsActive(placeholderArr.isActive);
          setIsAdmin(placeholderArr.isAdmin);
          setCanViewAllTransactions(placeholderArr.canViewAllTransactions);
          setAllTransactionLocations(placeholderArr.allTransactionLocations);
          setLastName(placeholderArr.lastName);
          setPhoneNumber(placeholderArr.phoneNumber);
          setSiteSettingsID(placeholderArr.siteSettingsID);
          setUserListID(placeholderArr.userListID);
          setUserSiteID(placeholderArr.userSiteID);

          placeholderArr.bankAccountList.forEach((element) => {
            element["default"] = false;
            element["permissibleItem"] = false;
            if (element.id === placeholderArr.defaultBank) {
              element["default"] = true;
            }
            placeholderArr.permissibleBanks.forEach((item) => {
              if (element.id === parseInt(item)) {
                element["permissibleItem"] = true;
              }
            });
          });

          placeholderArr.journalTypeLists.forEach((element) => {
            element["default"] = false;
            if (element.id === placeholderArr.defaultJournalType) {
              element["default"] = true;
            }
          });

          placeholderArr.locationList.forEach((element) => {
            element["default"] = false;
            element["permissibleItem"] = false;
            if (element.id === placeholderArr.defaultLocation) {
              element["default"] = true;
            }
            placeholderArr.permissibleLocations.forEach((item) => {
              if (element.id === parseInt(item)) {
                element["permissibleItem"] = true;
              }
            });
          });

          setBankAccountList(placeholderArr.bankAccountList);
          setJournalTypeLists(placeholderArr.journalTypeLists);
          setLocationList(placeholderArr.locationList);

          setPlaceholderListFilled(true);
        })
      ).catch((e) => {
        var message = apiError("API Get: ", e);
        setErrorList((errorList) => [...errorList, message]);
      });
    }
  }, [SiteID, Username, context.usr, params]);

  const resetForm = useCallback(async () => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (reloadPage) {
      // resetForm();
      setAddedSuccess(true);
    }
    loadData();
  }, [loadData, reloadPage]);

  const handleFormReset = (e) => {
    e.preventDefault();
    resetForm();
  };

  const handleSetEdit = () => {
    setNotEditable(false);
    setEditVisible(false);
    setUpdateSuccess(false);
    setAddedSuccess(false);
    setDataUpdated(false);
  };

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name, theData);
    var pattAddedSuccess = new RegExp("AddedSuccess"),
      pattErrorList = new RegExp("errorList"),
      pattClearErrorList = new RegExp("ClearErrorList");
    if (pattAddedSuccess.test(name)) {
      setReloadPage(true);
    } else if (pattErrorList.test(name)) {
      setValidationErrorList((errorList) => [...errorList, theData]);
    } else if (pattClearErrorList.test(name)) {
      if (name === "ClearValidationErrorList") {
        setValidationErrorList([]);
      } else {
        setErrorList(errorList.filter((item) => item !== theData));
      }
    } else {
      setter(theData);
    }
    setNoUpdateRequired(false);
    setDataUpdated(true);
  };

  const validateForm = () => {
    setValidationErrorList([]);
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "FirstName",
          data: FirstName,
          schema: FirstNameSchema,
        },
        {
          name: "LastName",
          data: LastName,
          schema: LastNameSchema,
        },
        {
          name: "PhoneNumber",
          data: PhoneNumber,
          schema: schemaPhoneNumber,
        },
      ];
      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            setValidationErrorList((errorList) => [...errorList, e]);
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (DataUpdated) {
      validateForm().then((response) => {
        setErrorList([]);
        doSubmit(true);
      });
    } else {
      setNoUpdateRequired(true);
    }
  };

  const doSubmit = async () => {
    try {
      let cognitoIdentityClient = new CognitoIdentityClient({
          region: process.env.REACT_APP_REGION,
        }),
        client = new CognitoIdentityProviderClient({
          region: process.env.REACT_APP_REGION,
          credentials: fromCognitoIdentityPool({
            client: cognitoIdentityClient,
            identityPoolId: Auth._config.identityPoolId,
          }),
        }),
        UpdateParams = {
          Username: Username,
          UserPoolId: Auth._config.userPoolId,
          UserAttributes: [
            {
              Name: "phone_number",
              Value: PhoneNumber,
            },
            {
              Name: "phone_number_verified",
              Value: "true",
            },
          ],
        },
        UpdateUser = await trackPromise(
          client
            .send(new AdminUpdateUserAttributesCommand(UpdateParams))
            .catch((e) => {
              console.log(e);
            })
        );
      // let MFAParams = {
      //   Username: Username,
      //   UserPoolId: Auth._config.userPoolId,
      //   SMSMfaSettings: {
      //     Enabled: true,
      //     PreferredMfa: true,
      //   },
      //   SoftwareTokenMfaSettings: {
      //     Enabled: false,
      //     PreferredMfa: false,
      //   },
      // },
      // SetUserMFA = await trackPromise(
      //   client
      //     .send(new AdminSetUserMFAPreferenceCommand(MFAParams))
      //     .catch((e) => {
      //       console.log(e);
      //     })
      // );

      await trackPromise(Promise.all([UpdateUser /*,SetUserMFA*/])).then(
        async (data) => {
          const DateTimeUpdated = GetDatetime(),
            outputJSON = {
              DateTimeUpdated: DateTimeUpdated,
              SiteID: SiteID,
              FirstName: FirstName,
              IsActive: IsActive,
              IsAdmin: IsAdmin,
              LastName: LastName,
              PhoneNumber: PhoneNumber,
              SiteSettingsID: SiteSettingsID,
            },
            theJournalList = SetListOutput(JournalTypeLists),
            theBankList = SetListOutput(BankAccountList),
            theLocationList = SetListOutput(LocationList);

          outputJSON["DefaultJournalType"] = theJournalList.DefaultItem;
          outputJSON["DefaultBank"] = theBankList.DefaultItem;
          outputJSON["PermissibleBanks"] = theBankList.PermissibleItems;
          outputJSON["DefaultLocation"] = theLocationList.DefaultItem;
          outputJSON["PermissibleLocations"] = theLocationList.PermissibleItems;

          outputJSON["UserListID"] = UserListID;
          outputJSON["UserSiteID"] = UserSiteID;
          outputJSON["CanViewAllTransactions"] = CanViewAllTransactions;
          outputJSON["AllTransactionLocations"] = AllTransactionLocations;

          // console.log(outputJSON);
          const putAPI = "api/UserSites/";

          await trackPromise(
            axios
              .put(putAPI, outputJSON)
              .then(() => {
                setUpdateSuccess(true);
                resetForm();
              })
              .catch((e) => {
                var message = apiError("PutAPI: ", e);
                setErrorList((errorList) => [...errorList, message]);
              })
          );
        }
      );
    } catch (e) {
      var message = apiError("PutAPI: ", e);
      setValidationErrorList((errorList) => [...errorList, message]);
    }
  };

  const SetListOutput = (inputList) => {
    var PermissibleItemList = [];
    var o = {};
    inputList.forEach((element) => {
      if (element.default) {
        o["DefaultItem"] = element.id;
      }
      if (element.permissibleItem) {
        PermissibleItemList.push(element.id);
      }
    });
    o["PermissibleItems"] = PermissibleItemList.join(",");
    return o;
  };

  if (PlaceholderListFilled) {
    return (
      <div className="UserSettings">
        <h1>User Settings</h1>
        <Row>
          {context.SettingsList.IsAdmin ? (
            <Col>
              <Link to="/UserList">
                <FaArrowCircleLeft /> Back to User List
              </Link>
            </Col>
          ) : (
            <Col sm={7}>
              <h2 className="right">User Preferences</h2>
            </Col>
          )}

          {editVisible ? (
            <Col className="right TooltipActionButton EditBtn">
              <Button variant="primary" type="button" onClick={handleSetEdit}>
                <FaRegEdit /> Edit
              </Button>
            </Col>
          ) : null}
        </Row>

        <OutputMessage
          errorList={errorList}
          ClearErrorList={handleCallback()}
          updateSuccess={updateSuccess}
          AddedSuccess={AddedSuccess}
        />
        <div className="detailsContent">
          <Form
            id="UserSettingsForm"
            onSubmit={handleSubmit}
            onReset={handleFormReset}
          >
            <FormLabelbox
              type="text"
              Label="Username"
              name="Username"
              placeholder={Username}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />

            {!notEditable && Username === context.usr ? (
              <FormContainer
                Label="User Password"
                name="UserPassword"
                content={
                  <Link to="/ResetPassword">
                    <Button variant="primary">Reset Password</Button>
                  </Link>
                }
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
              />
            ) : null}

            <FormInputBox
              type="text"
              Label="First Name"
              name="FirstName"
              placeholder={FirstName}
              value={handleCallback(setFirstName)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={handleCallback(setValidationErrorList)}
              schema={FirstNameSchema}
            />
            <FormInputBox
              type="text"
              Label="Last Name"
              name="LastName"
              placeholder={LastName}
              value={handleCallback(setLastName)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={handleCallback(setValidationErrorList)}
              schema={LastNameSchema}
            />
            <FormInputBox
              type="text"
              Label="Phone Number"
              name="PhoneNumber"
              placeholder={PhoneNumber}
              value={handleCallback(setPhoneNumber)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              disabled={notEditable}
              errorList={handleCallback(setValidationErrorList)}
              schema={schemaPhoneNumber}
            />
            {context.SettingsList.IsAdmin ? (
              <>
                <FormCheck
                  type="switch"
                  Label="Is Active"
                  name="IsActive"
                  id="IsActive"
                  initialState={IsActive}
                  value={handleCallback(setIsActive)}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={handleCallback(setValidationErrorList)}
                />

                <FormCheck
                  type="switch"
                  Label="Is Admin"
                  name="IsAdmin"
                  id="IsAdmin"
                  initialState={IsAdmin}
                  value={handleCallback(setIsAdmin)}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={errorList}
                  useWarningMessage={false}
                  messageMaxWidth="600px"
                  alertMessage={
                    <>
                      <h1>Setting this will grant the user Admin privilege.</h1>
                      <h4>
                        This will give them the ability to Add, Edit, View and
                        in some areas, Delete.
                      </h4>
                      <p>Are you sure you wish enable this setting?</p>
                    </>
                  }
                />

                <FormCheck
                  type="switch"
                  Label="Can View All User Transactions?"
                  name="CanViewAllTransactionssAdmin"
                  id="CanViewAllTransactionssAdmin"
                  initialState={CanViewAllTransactions}
                  value={handleCallback(setCanViewAllTransactions)}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={errorList}
                />

                <FormCheck
                  type="switch"
                  Label="Can View All Transaction Locations?"
                  name="AllTransactionLocationssAdmin"
                  id="AllTransactionLocationssAdmin"
                  initialState={AllTransactionLocations}
                  value={handleCallback(setAllTransactionLocations)}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={errorList}
                />
              </>
            ) : null}

            <UserListTable
              id="BankAccountList"
              Label="Bank"
              name="BankAccountList"
              ItemContent={BankAccountList}
              NewItemContent={handleCallback(setBankAccountList)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              required={false}
              disabled={notEditable}
              errorList={handleCallback}
            />

            <UserListTable
              id="JournalTypeLists"
              Label="Journal Type"
              name="JournalTypeLists"
              ItemContent={JournalTypeLists}
              NewItemContent={handleCallback(setJournalTypeLists)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              required={false}
              disabled={notEditable}
              errorList={handleCallback}
            />

            <UserListTable
              id="LocationList"
              Label="Location"
              name="LocationList"
              ItemContent={LocationList}
              NewItemContent={handleCallback(setLocationList)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              required={false}
              disabled={notEditable}
              errorList={handleCallback}
            />

            {!notEditable ? (
              <>
                <div className="container FormSave">
                  <Row>
                    <Col sm={LabelColumnAmount}></Col>
                    <Col sm={8} className="IconBtn">
                      <Button variant="primary" type="submit">
                        <FaSave /> Save
                      </Button>
                      {NoUpdateRequired ? DataNotChanged : null}
                      {ValidationErrorList.length >= 1 ? (
                        <ValidationError
                          errorListState={ValidationErrorList}
                          ClearErrorList={handleCallback(
                            setValidationErrorList
                          )}
                        />
                      ) : null}

                      <Button variant="primary" onClick={handleFormReset}>
                        <FaTimesCircle /> Cancel
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            ) : null}
          </Form>
        </div>
      </div>
    );
  }
};

export default UserSettings;
