import { Col, Row, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
export const LabelColumnAmount = 4;
export const InputColumnAmount = 4;
export const DataNotChanged = (
  <div className="invalid-tooltip-container">
    <div className="invalid-tooltip">
      Data hasn't changed, no need to update
    </div>
  </div>
);

export const ValidationError = (props) => {
  return (
    <div className="invalid-tooltip-container">
      <div className="invalid-tooltip">
        <span>
          Validation Error(s) detected -
          <span
            className="CloseButton close"
            onClick={() => {
              props.ClearErrorList("ClearValidationErrorList", "");
            }}
          >
            <AiOutlineClose />
          </span>
        </span>
        {props.errorListState.map((item, i) => {
          var list = JSON.stringify(item)
            .replace(/[[\]\\{}"]/g, "")
            .replace(/[,]/g, ",\n")
            .replace(/[:]/g, ": ");

          return <div key={i}>{list}</div>;
        })}
      </div>
    </div>
  );
};
export const UpdateSuccess = (
  <div className="detailsContent">
    <Row>
      <Col sm={LabelColumnAmount}></Col>
      <Col sm={InputColumnAmount}>
        <p>Update Success</p>
      </Col>
    </Row>
  </div>
);
export const RuntimeValuesMessage = (
  <>
    <Row className="FormGroup">
      <Form.Label column sm={LabelColumnAmount}></Form.Label>
      <Col sm={6}>
        Please note that the Values below are Case Sensitive and must match what
        has been set in Sage.
      </Col>
    </Row>
  </>
);
export const AddedSuccess = (
  <div className="detailsContent">
    <Row>
      <Col sm={LabelColumnAmount}></Col>
      <Col sm={InputColumnAmount}>
        <p>Record Successfully Added</p>
      </Col>
    </Row>
  </div>
);
// export const passRegex = /^[a-zA-Z0-9!@#$&`."]*$/;
export const passRegex = /^(?:[a-zA-Z0-9!"/=#$%&'()*+,.:;?@^_`{|} ~]|)*$/;
export const PasswordRequirements = () => {
  return (
    <>
      <h5>Requirements</h5>
      <p>
        Password needs to be at least 12 characters long and contain a mixture
        of:
      </p>
      <ul>
        <li>Upper and lower case characters</li>
        <li>The numbers 0 to 9</li>
        <li>Special characters (e.g. ! @ # $ &)</li>
        {/* <li>Special characters ! @ # $ & ` " </li> */}
        {/* <li>
          Special characters ! " ; # $ % & ' ( ) * + , - . / : ; ? @ [ ] ^ _ ` ~
          | &#60; &#61; &#62; &#123; &#125;
        </li> */}
      </ul>
      <p>
        We recommend the use of a password manager to randomly generate and
        store your new password.
      </p>
      <p>
        We also recommend that you don't reuse the password for another site.
      </p>
    </>
  );
};

export const emailRegex = /^[a-zA-Z0-9._@\-"]*$/;
export const emailRegexMessage = "Must be a valid email address";

export const inputRegex = /^[a-zA-Z0-9' _<>%.()-]*$/;
export const inputRegexMessage =
  "Only upper or lowercase characters A-Z, 0-9, spaces, brackets, full stops, hyphans, apostrophes, underscores, chevrons and percentage signs are allowed.";

export const ValueInputRegex = /^[a-zA-Z0-9' _<>%.()-]*$/;
export const ValueInputRegexMessage =
  "This Value may only contain upper or lowercase characters A-Z, 0-9, spaces, hyphans, apostrophes, underscores, chevrons and percentage signs.";
