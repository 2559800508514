import React, { useEffect, useMemo } from "react";
import { useTable } from "react-table";
import { /*Button,*/ Table } from "react-bootstrap";
import download from "downloadjs";
import { FaFileDownload } from "react-icons/fa";
import { SetObserver } from "../../libs/Observer";
import DropdownOverlayFormatterRow from "../../libs/DropdownOverlayFormatterRow";

const TransactionsAttachments = (props) => {
  const columns = useMemo(
    () => [
      // {
      //   Header: "",
      //   accessor: "data",
      //   Cell: (cell) => {
      //     return (
      //       <Button
      //         onClick={() => {
      //           download(atob(cell.row.original.data), cell.row.original.name, {
      //             type: cell.row.original.type,
      //           });
      //         }}
      //       >
      //         <FaFileDownload />
      //       </Button>
      //     );
      //   },
      // },
      {
        Header: "Attachment(s)",
        accessor: "name",
        Cell: (cell) => {
          return (
            <>
              <FaFileDownload /> {cell.value}
            </>
          );
        },
      },
    ],

    []
  );

  const data = useMemo(() => props.Content, [props.Content]);

  useEffect(() => {
    SetObserver();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    rows,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
  });

  if (data.length > 0)
    return (
      <div className="TransactionLineRow">
        <div className="react-table">
          <Table hover size="sm" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody id={"TransactionLineRow"} {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return DropdownOverlayFormatterRow(
                  <tr
                    {...row.getRowProps()}
                    onClick={() => {
                      var cell = row.original;
                      download(atob(cell.data), cell.name, {
                        type: cell.type,
                      });
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>,
                  "View Attachment",
                  "top"
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    );
};

export default TransactionsAttachments;
