import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppConsumer } from "../libs/contextLib";

export default function AuthenticatedRoute({ children, ...rest }) {
  let { pathname, search } = useLocation();

  // var p = pathname;
  // var canViewParam = p.replace("/", "");

  return (
    <AppConsumer>
      {(user) =>
        user.isAuthenticated ? (
          // user.SettingsList.IsAdmin || canViewParam === "" ? ( //if user is admin then they can access all areas - no need to check the remaining conditions
          children
        ) : (
          // ) : user.SettingsList.length !== 0 && !(canViewParam in user.SettingsList.AdminOnly) ? ( //redirect to NoAccess if page is in the Admin List
          //   !(canViewParam in user.SettingsList.CanView) || user.SettingsList.CanView[canViewParam] ? ( //check if the user can see the page in question
          //     children
          //   ) : (
          //     <Navigate to={`/noaccess`} />
          //   )
          // ) : (
          // <Navigate to={`/noaccess`} />
          // )
          <Navigate to={`/login?redirect=${pathname}${search}`} />
        )
      }
    </AppConsumer>
  );
}
