import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { FiCircle } from "react-icons/fi";
import { FaRegDotCircle } from "react-icons/fa";

const FormRadio = (props) => {
  const [name, setname] = useState(props.initialState);

  useEffect(() => {
    if (!props.MultiSelect) {
      setname(props.initialState);
    }
  }, [props.MultiSelect, props.initialState]);

  const handleChange = (e) => {
    setname(e);
    props.value(props.id, e);
  };

  return !props.hidden ? (
    <Form.Group className={"FormGroup"} as={Row} controlId={props.name}>
      <Form.Label column sm={props.LabelColumnAmount}>
        {props.Label}
      </Form.Label>
      <Col
        sm={props.InputColumnAmount}
        // className={props.MultiSelect ? "MultiSelect" : ""}
        className={"RadioGroup"}
      >
        <ToggleButtonGroup
          type={props.MultiSelect ? "checkbox" : "radio"}
          name="type"
          value={name}
          onChange={handleChange}
        >
          {props.valueList.map((radio, i) => (
            <ToggleButton
              id={i}
              key={i}
              disabled={props.disabled}
              value={radio.id}
            >
              {props.MultiSelect ? (
                name.includes(radio.id) ? (
                  <FaRegDotCircle />
                ) : (
                  <FiCircle />
                )
              ) : radio.id === name ? (
                <FaRegDotCircle />
              ) : (
                <FiCircle />
              )}
              <>&nbsp;{radio.name}</>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Col>
    </Form.Group>
  ) : null;
};

export default FormRadio;
