import React, { useState, createContext } from "react";

export const AppContext = createContext({
  isAuthenticated: false,
  usr: "",
  SettingsList: "",
  INTACCT_API_BASE_URL: "",

  setIsAuthenticated: () => {},
  setUsr: () => {},
  setSettingsList: () => {},
});

export const AppProvider = (props) => {
  let [isAuthenticated, setIsAuthenticated] = useState(false);
  let [usr, setUsr] = useState("");
  let [SettingsList, setSettingsList] = useState("");

  let state = {
    isAuthenticated,
    usr,
    SettingsList,

    setIsAuthenticated,
    setUsr,
    setSettingsList,
  };

  return (
    <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
  );
};

export const AppConsumer = AppContext.Consumer;
export default AppContext;
