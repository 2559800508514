export default function SetSettingsList(placeholderArr) {
  let SettingsList = {
    AdminOnly: {
      UserList: true,
      SiteSettings: true,
    },
    CanView: {
      CompanySettings: placeholderArr.canAccessCompanySettings,
      CompanyListDetails: placeholderArr.canAccessCompanyList,
      // BankDetails: placeholderArr.canViewBankDetails,
      // CustomerList: placeholderArr.canViewCustomerList,
    },
    CanAdd: {
      // BankDetails: placeholderArr.canAddBankDetails,
      // NewCompanies: placeholderArr.canAddNewCompanies,
      // DDRuns: placeholderArr.canCreateDDRuns,
    },
    CanDelete: {
      // DDRuns: placeholderArr.canDeleteDDRuns,
      // DDAccounts: placeholderArr.canRemoveDDAccounts,
    },

    CanEdit: {
      // BankDetails: placeholderArr.canEditBankDetails,
      CompanySettings: placeholderArr.canEditCompanySettings,
    },
    CanPost: {
      // DDRuns: placeholderArr.canPostDDRuns,
    },
    IsAdmin: placeholderArr.isAdmin,
    IsSuperAdmin: placeholderArr.isSuperAdmin,
  };

  return SettingsList;
}
