import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DropdownOverlayFormatterRow = (cellContent, label, placement) => {
  if (label) {
    return (
      <OverlayTrigger
        placement={!placement ? "right" : placement}
        overlay={
          <Tooltip id="ReferenceTooltip" className="">
            {label ? label : cellContent}
          </Tooltip>
        }
      >
        {cellContent}
      </OverlayTrigger>
    );
  } else return cellContent;
};

export default DropdownOverlayFormatterRow;
