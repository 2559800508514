import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Auth } from "aws-amplify";
import { FaRegEdit, FaTimesCircle, FaSave } from "react-icons/fa";
import { AppContext } from "../libs/contextLib";
import apiError from "../libs/apiError";
import { trackPromise } from "react-promise-tracker";
import { Form, Col, Row, Button } from "react-bootstrap";
import FormLabelbox from "../components/Form/FormLabelbox";
import FormInputBox from "../components/Form/FormInputbox";
// import FormCheck from "../components/Form/FormCheck";
import FormPassword from "../components/Form/FormPassword";
// import CheckConfirmOK from "../components/Form/CheckConfirmOK";
import GetDatetime from "../libs/GetDatetime";
import * as yup from "yup";
import OutputMessage from "../components/OutputMessage";
import {
  LabelColumnAmount,
  InputColumnAmount,
  DataNotChanged,
  ValidationError,
  passRegex,
  inputRegex,
  inputRegexMessage,
} from "../libs/Variables";
import { handleValidation } from "../components/handleValidation";
import { Encrypt } from "../libs/Crypto";

const CompanyIDSchema = yup
    .string()
    .required("Company ID is required")
    .matches(inputRegex, "Company ID" + inputRegexMessage)
    .max(100, "Company ID must be less than 100 characters"),
  UserIDSchema = yup
    .string()
    .required("User ID is required")
    .matches(inputRegex, "User ID" + inputRegexMessage)
    .max(250, "User ID must be less than 250 characters"),
  UserPasswordSchema = yup
    .string()
    .required("User Password is required")
    .matches(passRegex, "User Password invalid")
    .max(50, "User Password must be less than 50 characters");

const SiteSettings = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;

  const [notEditable, setNotEditable] = useState(false);
  const [editVisible, setEditVisible] = useState(true);
  const [NoUpdateRequired, setNoUpdateRequired] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [ReLoginRequired, setReLoginRequired] = useState(false);
  const [ErrorList, setErrorList] = useState([]);
  const [ValidationErrorList, setValidationErrorList] = useState([]);
  const [PostFeedback, setPostFeedback] = useState();
  const [SiteName, setSiteName] = useState();
  // const [SiteID, setSiteID] = useState();
  const [SiteSettingsID, setSiteSettingsID] = useState();
  const [ProductName, setProductName] = useState();
  const [CompanyID, setCompanyID] = useState();
  const [UserID, setUserID] = useState();
  const [UserPassword, setUserPassword] = useState();
  // const [IsActive, setIsActive] = useState();
  const [UseIPWhitelist, setUseIPWhitelist] = useState();
  const [DataUpdated, setDataUpdated] = useState(false);
  const [PlaceholderListFilled, setPlaceholderListFilled] = useState(false);

  const loadData = useCallback(async () => {
    if (params !== undefined && params) {
      setUpdateSuccess(params.updateSuccess);
      setPostFeedback(params.PostFeedback);
    }

    setNotEditable(true);
    setEditVisible(true);
    let SiteName = sessionStorage.getItem("siteName");
    // let SiteID = sessionStorage.getItem("siteID");

    await trackPromise(
      Promise.all([
        axios.get("/api/sitesettings/" + SiteName).catch((e) => {
          var message = apiError("API sitesettings: ", e);
          setErrorList((ErrorList) => [...ErrorList, message]);
        }),
      ])
        .then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            let PlaceholderList = responses[0].data[0];

            setSiteName(SiteName);
            setSiteSettingsID(PlaceholderList.siteSettingsID);
            setProductName(PlaceholderList.productName);
            setCompanyID(PlaceholderList.intacctCompanyID);
            setUserID(PlaceholderList.userId);
            setUserPassword(PlaceholderList.userPassword);
            // setIsActive(PlaceholderList.isActive);
            setUseIPWhitelist(PlaceholderList.useIpwhiteList);
            setPlaceholderListFilled(true);
          } else {
            throw new Error(
              "An Error has occured in the Site Settings API Call."
            );
          }
        })
        .catch((e) => {
          var message = apiError("API sitesettings: ", e);
          setErrorList((ErrorList) => [...ErrorList, message]);
        })
    );
  }, [params]);

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [loadData]);

  const resetForm = useCallback(async () => {
    loadData();
  }, [loadData]);

  const handleFormReset = (e) => {
    e.preventDefault();
    resetForm();
  };

  const handleSetEdit = () => {
    setNotEditable(false);
    setEditVisible(false);
    setUpdateSuccess(false);
    setDataUpdated(false);
    setPostFeedback("");
  };

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name, theData);
    const NoUpdateRequired = false,
      DataUpdated = true;
    var pattIPWhitelistSetting = new RegExp("UseIPWhitelist"),
      pattUserPassword = new RegExp("UserPassword"),
      pattSenderPassword = new RegExp("SenderPassword"),
      pattClearErrorList = new RegExp("ClearErrorList");
    if (pattIPWhitelistSetting.test(name)) {
      setReLoginRequired(true);
      setter(theData);
    } else if (pattClearErrorList.test(name)) {
      if (name === "ClearValidationErrorList") {
        setValidationErrorList([]);
      } else {
        setErrorList(ErrorList.filter((item) => item !== theData));
      }
    } else if (pattUserPassword.test(name)) {
      setter(Encrypt(theData));
    } else if (pattSenderPassword.test(name)) {
      setter(Encrypt(theData));
    } else {
      setter(theData);
    }
    setNoUpdateRequired(NoUpdateRequired);
    setDataUpdated(DataUpdated);
    setter(theData);
  };

  const validateForm = () => {
    return new Promise(async (resolve, reject) => {
      if (ProductName === "Sage Intacct") {
        let items = [
          {
            name: "CompanyID",
            data: CompanyID,
            schema: CompanyIDSchema,
          },
          {
            name: "UserID",
            data: UserID,
            schema: UserIDSchema,
          },
          {
            name: "UserPassword",
            data: UserPassword,
            schema: UserPasswordSchema,
          },
        ];

        var Status = [];

        items.forEach(async (element, i) => {
          Status[i] = false;
          await handleValidation(element)
            .then(() => {
              Status[i] = true;
            })
            .catch((e) => {
              reject(e);
            });
          if (!Status.includes(false)) {
            resolve(true);
          }
        });
      } else {
        resolve(true);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateForm()
      .then((response) => {
        setErrorList([]);
        doSubmit();
      })
      .catch((e) => {
        setValidationErrorList(JSON.stringify(e));
      });
  };

  const doSubmit = async () => {
    let DateTimeUpdated;
    if (DataUpdated) {
      DateTimeUpdated = GetDatetime();

      let outputJSON = {
        DateTimeUpdated: DateTimeUpdated,
        IntacctCompanyID: CompanyID,
        UserID: UserID,
        UserPassword: UserPassword,
        UseIPWhitelist: UseIPWhitelist,
      };

      // context.setIPWhitelistSetting = UseIPWhitelist;
      // console.log("put");
      // console.log(outputJSON);
      await trackPromise(
        axios
          .put("api/sitesettings/" + SiteSettingsID, outputJSON)
          .then((response) => {
            // console.log(response);
            if (ReLoginRequired) {
              resetForm();
              SignOut();
            } else {
              setUpdateSuccess(true);
              setNotEditable(true);
              setEditVisible(true);
              resetForm();
            }
          })
          .catch((e) => {
            var message = apiError("Put SiteSettings API : ", e);
            setErrorList((ErrorList) => [...ErrorList, message]);
          })
      );
    } else {
      setNoUpdateRequired(true);
    }
  };

  const SignOut = () => {
    context.setIsAuthenticated(false);
    context.setSettingsList([]);
    sessionStorage.clear();
    Auth.signOut({ global: true })
      .then(() => {
        navigate("/login");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const productSwitch = () => {
    return renderIntacct();
  };

  const renderIntacct = () => {
    return (
      <>
        {/* <h2>Intacct Credentials</h2> */}

        {/* <div className="detailsContent"> */}
        {CompanyID ? (
          <FormInputBox
            type="text"
            Label="Company ID"
            name="CompanyID"
            placeholder={CompanyID}
            value={handleCallback(setCompanyID)}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            disabled={notEditable}
            errorList={handleCallback(setValidationErrorList)}
            schema={CompanyIDSchema}
          />
        ) : null}

        <FormInputBox
          type="text"
          Label="User ID"
          name="UserID"
          placeholder={UserID}
          value={handleCallback(setUserID)}
          LabelColumnAmount={LabelColumnAmount}
          InputColumnAmount={InputColumnAmount}
          disabled={notEditable}
          errorList={handleCallback(setValidationErrorList)}
          schema={UserIDSchema}
        />

        {!notEditable ? (
          <FormPassword
            Label="User Password"
            name="UserPassword"
            value={handleCallback(setUserPassword)}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            disabled={notEditable}
            errorList={handleCallback(setValidationErrorList)}
            schema={UserPasswordSchema}
          />
        ) : null}

        {siteWideSettings()}
        {/* </div> */}
      </>
    );
  };

  const siteWideSettings = () => {
    return (
      <>
        {/* <h2>Sitewide Settings</h2> */}

        {!notEditable ? (
          // <div className="container FormSave">
          <Row>
            <Col sm={LabelColumnAmount}></Col>
            <Col sm={8} className="IconBtn">
              <Button variant="primary" type="submit">
                <FaSave /> Save
              </Button>
              {NoUpdateRequired ? DataNotChanged : null}
              {ValidationErrorList.length >= 1 ? (
                <ValidationError
                  errorListState={ValidationErrorList}
                  ClearErrorList={handleCallback(setValidationErrorList)}
                />
              ) : null}

              <Button variant="primary" onClick={handleFormReset}>
                <FaTimesCircle /> Cancel
              </Button>
            </Col>
          </Row>
        ) : // </div>
        null}
      </>
    );
  };

  const renderContent = () => {
    return (
      <div className="SiteSettings">
        <h1>Site Settings</h1>
        <Row>
          {editVisible ? (
            <Col className="right TooltipActionButton">
              <Button variant="primary" type="button" onClick={handleSetEdit}>
                <FaRegEdit /> Edit
              </Button>
            </Col>
          ) : null}
        </Row>

        <OutputMessage
          errorList={ErrorList}
          ClearErrorList={handleCallback()}
          updateSuccess={updateSuccess}
          PostFeedback={PostFeedback}
        />

        <div className="detailsContent">
          <Form
            id="SiteSettingsDetailsForm"
            onSubmit={handleSubmit}
            onReset={handleFormReset}
          >
            <FormLabelbox
              type="text"
              Label="Site Name"
              name="siteName"
              placeholder={SiteName}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
            />

            {productSwitch()}
          </Form>
        </div>
      </div>
    );
  };

  if (!PlaceholderListFilled) {
    return null;
  } else {
    return renderContent();
  }
};

export default SiteSettings;
