import React, { useContext, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Navbar, Row, Col, Button } from "react-bootstrap";
import { AppContext } from "../libs/contextLib";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Pdf from "../downloads/UserGuide.pdf";

const LoginExpireLength = 480;
const Header = (props) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);

  useEffect(() => {
    var LoggedInTime = sessionStorage.getItem("LoggedInTime");
    var date = moment(LoggedInTime, "DD-MM-YYYY HH:mm:ss");
    var LoggedInTimePlusExpireLength = date
      .add(LoginExpireLength, "m")
      .format("DD-MM-YYYY HH:mm:ss");
    var dateToCompare = moment(
      LoggedInTimePlusExpireLength,
      "DD-MM-YYYY HH:mm:ss"
    );
    let now = moment(moment(), "DD-MM-YYYY HH:mm:ss");
    if (now.isSameOrAfter(dateToCompare)) {
      SignOut();
    }
  });

  const SignOut = (SelfLoggedOut) => {
    context.setIsAuthenticated(false);
    context.setSettingsList([]);

    sessionStorage.clear();
    Auth.signOut({ global: true })
      .then(() => {
        var Feedback =
          "You have been logged out as your authentication token needs to be refreshed. Please login again.";
        if (SelfLoggedOut) {
          Feedback = "You have been succcesfully logged out.";
        }
        navigate("/login", { state: { PostFeedback: [Feedback] } });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleLogout = () => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className="ConfirmAlert">
            <div className="AlertBody">
              <h1>Do you want to log out?</h1>
              <p>You will lose all unsaved data if you do.</p>
              <div className="ButtonGroup">
                <Button
                  onClick={() => {
                    SignOut(true);

                    onClose();
                  }}
                >
                  Yes
                </Button>
                <Button onClick={onClose}>No</Button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="header">
      <Container>
        <div className="headerContainer">
          <Row>
            <Col sm={2}>
              <Navbar.Brand href="/">
                <img
                  src={require("../img/logo2.png")}
                  alt="Solutions Web App"
                />
              </Navbar.Brand>
            </Col>
            {context.isAuthenticated ? (
              <>
                <Col className="welcome">
                  <span>{context.usr}</span>
                </Col>
                {context.SettingsList.IsSuperAdmin ? (
                  <Col lg={"auto"} sm={2} xs={6}>
                    <LinkContainer to="/NewSite">
                      <Button variant="primary">
                        New<br></br>Site
                      </Button>
                    </LinkContainer>
                  </Col>
                ) : null}
                <Col lg={"auto"} sm={2} xs={6}>
                  <Button
                    variant="primary"
                    href={Pdf}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Help<br></br>Guide
                  </Button>
                </Col>
                <Col lg={"auto"} sm={2} xs={6}>
                  <LinkContainer to="/UserSettings">
                    <Button variant="primary">
                      User<br></br>Preferences
                    </Button>
                  </LinkContainer>
                </Col>
                <Col lg={"auto"} sm={2} xs={6}>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Col>
              </>
            ) : null}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Header;
