import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaKey } from "react-icons/fa";
import { handleValidation } from "../handleValidation";
const DOMPurify = require("dompurify");

class FormPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.state.UpdateContent = false;
    this.state.data = "";
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleEdit() {
    this.setState({
      UpdateContent: true,
    });
  }

  handleChange(event) {
    let cleanedValue = DOMPurify.sanitize(event.target.value);
    this.setState({
      data: cleanedValue,
    });
    this.props.value(this.props.name, cleanedValue);
  }

  handleBlur(e) {
    if (this.props.schema) {
      let items = {
        name: this.props.name,
        data: this.state.data,
        schema: this.props.schema,
      };
      handleValidation(items)
        .then((response) => {
          this.setState({ errorList: "" }, () => {
            this.props.value(this.props.name, e.target.value);
          });
        })
        .catch((e) => {
          this.setState({ errorList: e }, () => {});
        });
    }
  }

  renderForm() {
    let name = this.props.name,
      UpdateContent = this.state.UpdateContent;
    return (
      <Form.Group className={"FormGroup"} as={Row} controlId={name}>
        <Form.Label column sm={this.props.LabelColumnAmount}>
          {this.props.Label}
        </Form.Label>
        <Col sm={this.props.InputColumnAmount}>
          {!UpdateContent ? (
            <Button type="primary" onClick={this.handleEdit}>
              <FaKey /> Update {this.props.Label}
            </Button>
          ) : (
            <Form.Control
              type="password"
              aria-describedby="inputGroupPrepend"
              name={name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              required={this.props.required}
              disabled={this.props.disabled}
            />
          )}
        </Col>
        <Col>
          {this.state.errorList && this.state.errorList[name] ? (
            <div className="invalid-tooltip typeError">
              {this.state.errorList[name]}
            </div>
          ) : null}
          {!this.state.errorList && this.props.errorList[name] ? (
            <div className="invalid-tooltip typeError">
              {this.props.errorList[name]}
            </div>
          ) : null}
        </Col>
      </Form.Group>
    );
  }
  render() {
    return !this.props.hidden ? (
      this.props.tooltip ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={this.props.name + "Tooltip"} className="">
              {this.props.tooltip}
            </Tooltip>
          }
        >
          {this.renderForm()}
        </OverlayTrigger>
      ) : (
        this.renderForm()
      )
    ) : null;
  }
}

export default FormPassword;
