import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import { Container, Nav, Navbar, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "../libs/contextLib";

const Tabs = () => {
  const context = useContext(AppContext);
  const location = useLocation();

  return (
    <div className="Tabs">
      <Container>
        <Row>
          {context.isAuthenticated ? (
            <Navbar id="main-nav">
              <Nav justify variant="tabs" className="" activeKey="">
                <Nav.Item>
                  <LinkContainer
                    to="/ReceiptList"
                    className={
                      location.pathname === "/ReceiptList" ||
                      // location.pathname === "/TransactionEnquiry" ||
                      location.pathname === "/"
                        ? " active"
                        : ""
                    }
                  >
                    <Nav.Link>Receipt Entry</Nav.Link>
                  </LinkContainer>
                </Nav.Item>

                {context.SettingsList.length !== 0 &&
                (context.SettingsList.IsAdmin ||
                  context.SettingsList.CanView.CompanySettings) ? (
                  <Nav.Item>
                    <LinkContainer to="/CompanyListDetails">
                      <Nav.Link>Company Settings</Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                ) : null}
                {context.SettingsList.length !== 0 &&
                context.SettingsList.IsAdmin ? (
                  <>
                    <Nav.Item>
                      <LinkContainer
                        to="/UserList"
                        className={
                          location.pathname === "/UserSettings" ? " active" : ""
                        }
                      >
                        <Nav.Link>User Settings</Nav.Link>
                      </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                      <LinkContainer to="/SiteSettings">
                        <Nav.Link>Site Settings</Nav.Link>
                      </LinkContainer>
                    </Nav.Item>
                  </>
                ) : null}
              </Nav>
              <Navbar.Toggle aria-controls="main-nav" />
            </Navbar>
          ) : null}
        </Row>
      </Container>
    </div>
  );
};

export default Tabs;
