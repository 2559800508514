import React, { useContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import RouteList from "./RouteList";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import Header from "./components/Header";
import Tabs from "./components/Tabs";
import Footer from "./components/Footer";
import apiError from "./libs/apiError";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import SetSettingsList from "./components/SetSettingsList";
import OutputMessage from "./components/OutputMessage";

export default function App(props) {
  const context = useContext(AppContext);
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      if (!context.isAuthenticated) {
        await trackPromise(
          Promise.all([
            axios.get("/api/Environment/").catch((e) => {
              var message = apiError("API Get Environment: ", e);
              setErrorList((prevState) => ({ ...prevState, message }));
            }),
          ]).then(async (responses) => {
            let env = responses[0].data,
              USER_POOL_ID =
                env.REACT_APP_USER_POOL_ID !== "unknown"
                  ? env.REACT_APP_USER_POOL_ID
                  : process.env.REACT_APP_USER_POOL_ID,
              IDENTITY_POOL_ID =
                env.REACT_APP_IDENTITY_POOL_ID !== "unknown"
                  ? env.REACT_APP_IDENTITY_POOL_ID
                  : process.env.REACT_APP_IDENTITY_POOL_ID,
              CLIENT_ID =
                env.REACT_APP_CLIENT_ID !== "unknown"
                  ? env.REACT_APP_CLIENT_ID
                  : process.env.REACT_APP_CLIENT_ID;
            if (env) {
              Auth.configure({
                mandatorySignIn: true,
                region: process.env.REACT_APP_REGION,
                userPoolId: USER_POOL_ID,
                identityPoolId: IDENTITY_POOL_ID,
                userPoolWebClientId: CLIENT_ID,
                storage: sessionStorage,
              });

              await Auth.currentSession()
                .then(async (u) => {
                  let usr = u.idToken.payload.email;
                  let SiteID = sessionStorage.getItem("siteID");
                  await trackPromise(
                    Promise.all([
                      axios
                        .get(
                          "/api/UserSites/GetUserSettings/" + SiteID + "/" + usr
                        )
                        .catch((e) => {
                          var message = apiError("API Get: ", e);
                          setErrorList((prevState) => ({
                            ...prevState,
                            message,
                          }));
                        }),
                    ]).then((responses) => {
                      if (responses[0] && responses[0] !== undefined) {
                        var placeholderArr =
                          responses[0].data[responses[0].data.length - 1];
                        let SettingsList = SetSettingsList(placeholderArr);
                        context.setIsAuthenticated(true);
                        context.setUsr(usr);
                        context.setSettingsList(SettingsList);
                      }
                    })
                  );
                })
                .catch((e) => {
                  if (e !== "No current user") {
                    console.log(e);
                  }
                });
            }
          })
        );
      }
    };

    loadData();
  }, [context]);

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name, theData);
    var pattClearErrorList = new RegExp("ClearErrorList");
    if (pattClearErrorList.test(name)) {
      setErrorList(errorList.filter((item) => item !== theData));
    }
  };

  return (
    <div className="backgroundContainer">
      <Header />
      <Tabs />
      <main className="contentContainer">
        <Container className="content">
          <div className="ContentBackground">
            {errorList ? (
              <OutputMessage
                errorList={errorList}
                ClearErrorList={handleCallback()}
              />
            ) : null}
            <RouteList />
          </div>
        </Container>
      </main>
      <Footer />
    </div>
  );
}
