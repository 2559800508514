import React, { useContext } from "react";
import { AppContext } from "../../../libs/contextLib";
import { Row, Col, Form } from "react-bootstrap";
import UserListTablePopup from "./UserListTablePopup";

const UserListTable = (props) => {
  const context = useContext(AppContext);
  const handleCallback = (name, theData) => {
    // console.log(name, theData);
    var pattErrorList = new RegExp("errorList"),
      pattSave = new RegExp("Save");

    if (pattErrorList.test(name)) {
      props.errorList(name, theData);
    } else if (pattSave.test(name)) {
      props.NewItemContent(props.name, theData);
    }
  };

  return (
    <div className="UserListTable">
      <Row>
        <Form.Group
          className={"FormGroup"}
          as={Row}
          controlId={`Default ${props.Label}`}
        >
          <Form.Label column sm={props.LabelColumnAmount}>
            {`Default ${props.Label}`}
          </Form.Label>

          {props.ItemContent.map((element, i) =>
            element.default ? (
              <Col key={i} sm={props.InputColumnAmount}>
                <Form.Control plaintext readOnly value={element.content} />
              </Col>
            ) : null
          )}
        </Form.Group>

        {context.SettingsList.IsAdmin ? (
          <>
            {props.name !== "JournalTypeLists" ? (
              <Form.Group
                className={"FormGroup"}
                as={Row}
                controlId={`Permissible ${props.Label}s`}
              >
                <Form.Label column sm={props.LabelColumnAmount}>
                  {`Permissible ${props.Label}s`}
                </Form.Label>

                <Col sm={props.InputColumnAmount}>
                  {props.ItemContent.map((element, i) =>
                    element.permissibleItem ? (
                      <Form.Control
                        key={i}
                        plaintext
                        readOnly
                        value={element.content}
                      />
                    ) : null
                  )}
                </Col>
              </Form.Group>
            ) : null}
          </>
        ) : null}

        {!props.disabled ? (
          <div className="UserListTableEditButton">
            <UserListTablePopup
              name={props.name}
              buttonLabel={props.Label}
              rowData={props.ItemContent}
              ValuesToUpdate={handleCallback}
              errorList={handleCallback}
            />
          </div>
        ) : null}
      </Row>
    </div>
  );
};

export default UserListTable;
