import React, { useEffect, useMemo, useContext } from "react";
import { useTable } from "react-table";
import { Table } from "react-bootstrap";
import { SetObserver } from "../../libs/Observer";
import { ReceiptContext } from "../../libs/ReceiptContextLib";
import NewReceiptLine from "./NewReceiptLine";
import GrandTotalReceiptLine from "./GrandTotalReceiptLine";
import AddNewReceiptLine from "./AddNewReceiptLine";

const ReceiptFormLines = (props) => {
  const Receipt = useContext(ReceiptContext);

  const columns = useMemo(
    () =>
      Receipt.ReceiptHeaderItems.TaxType === "None"
        ? [
            {
              Header: "Item Code",
              accessor: "",
            },
            {
              Header: "Class",
              accessor: "",
            },
            {
              Header: "Department",
              accessor: "",
            },
            {
              Header: "Memo",
              accessor: "",
            },
            {
              id: "Debit",
              accessor: "",
              Header: () => {
                return <div className="right">Debit</div>;
              },
            },
            {
              id: "Credit",
              accessor: "",
              Header: () => {
                return <div className="right">Credit</div>;
              },
            },
            {
              id: "Total Debit",
              accessor: "",
              Header: () => {
                return <div className="right">Total Debit</div>;
              },
            },
            {
              id: "Total Credit",
              accessor: "",
              Header: () => {
                return <div className="right">Total Credit</div>;
              },
            },
            { Header: " ", accessor: "" },
          ]
        : [
            {
              Header: "Item Code",
              accessor: "",
            },
            {
              Header: "Class",
              accessor: "",
            },
            {
              Header: "Department",
              accessor: "",
            },
            {
              Header: "Memo",
              accessor: "",
            },
            {
              id: "Debit",
              Header: () => {
                return <div className="right">Debit</div>;
              },
              accessor: "",
            },
            {
              id: "Credit",
              accessor: "",
              Header: () => {
                return <div className="right">Credit</div>;
              },
            },
            {
              Header: "Tax Details",
              accessor: "",
            },
            {
              id: "Rate (%)",
              accessor: "",
              Header: () => {
                return <div className="right">Rate (%)</div>;
              },
            },
            {
              id: "Tax Amount",
              accessor: "",
              Header: () => {
                return <div className="right">Tax Amount</div>;
              },
            },
            {
              id: "Total Debit",
              accessor: "",
              Header: () => {
                return <div className="right">Total Debit</div>;
              },
            },
            {
              id: "Total Credit",
              accessor: "",
              Header: () => {
                return <div className="right">Total Credit</div>;
              },
            },
            { Header: " ", accessor: "" },
          ],

    [Receipt.ReceiptHeaderItems.TaxType]
  );

  const data = useMemo(() => [], []);

  useEffect(() => {
    SetObserver();
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
  });

  if (
    Object.keys(Receipt.ReceiptHeaderItems).length === 0 &&
    Object.keys(Receipt.RowContentList).length === 0
  ) {
    return null;
  } else {
    const AddMargin = !props.ShowCollapse ? { marginTop: "50px" } : null;
    return (
      <div className="ReceiptFormLines" style={AddMargin}>
        <div className="react-table">
          <Table hover size="sm" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody id={"ReceiptFormLines"} {...getTableBodyProps()}>
              {Receipt.RowContentList.map((Row, i) => (
                <NewReceiptLine key={Row.ID} ID={Row.ID} />
              ))}
              <AddNewReceiptLine />
              <GrandTotalReceiptLine />
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
};

export default ReceiptFormLines;
