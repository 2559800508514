import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "react-bootstrap";

export default function CheckConfirm(message, messageMaxWidth) {
  return new Promise((resolve, reject) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className="ConfirmAlert">
            <div className="AlertBody" style={{ maxWidth: messageMaxWidth }}>
              {message}
              <div
                className="ButtonGroup"
                style={{ display: "block", textAlign: "center" }}
              >
                <Button
                  onClick={() => {
                    resolve(true);
                    onClose();
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    reject(false);
                    onClose();
                  }}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        );
      },
    });
  });
}
