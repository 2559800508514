import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation(),
      navigate = useNavigate(),
      params = useParams(),
      router = { location, navigate, params };
    return <Component {...props} {...router} />;
  }
  return ComponentWithRouterProp;
}
