import React, { useState, useEffect, useCallback } from "react";
import UserListTable from "../components/UserList/UserListTable";
import axios from "axios";
import apiError from "../libs/apiError";
import { trackPromise } from "react-promise-tracker";
import IsError from "../components/IsError";

const UserList = () => {
  const [errorList, setErrorList] = useState([]);
  const [List, setList] = useState();

  const loadData = useCallback(async () => {
    let theSiteID = sessionStorage.getItem("siteID");

    if (theSiteID) {
      await trackPromise(
        Promise.all([
          axios.get("/api/UserSites/GetUserList/" + theSiteID).catch((e) => {
            apiError("apiUrl Get: ", e);
          }),
        ])
          .then((responses) => {
            if (responses[0] && responses[0] !== undefined) {
              setList(responses[0].data);
            } else {
              throw new Error("No Active Users found");
            }
          })
          .catch((e) => {
            var message = apiError("UserList: ", e);
            setErrorList((prevState) => ({ ...prevState, message }));
          })
      );
    }
  }, []);

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [loadData]);

  return (
    <div className="UserList">
      <h1>User List</h1>
      {errorList.length >= 1 ? (
        <IsError errorList={errorList} />
      ) : List ? (
        <UserListTable rows={List} />
      ) : null}
    </div>
  );
};

export default UserList;
