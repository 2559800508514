import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { Col, Form, Button } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { AppContext } from "../../libs/contextLib";
import apiError from "../../libs/apiError";
import { trackPromise } from "react-promise-tracker";
import TransactionEnquiryTable from "./TransactionEnquiryTable";
import OutputMessage from "../OutputMessage";
import FormDatePicker from "../Form/FormDatePicker";
import FormDropdown from "../Form/FormDropdown";
import GetDatetime from "../../libs/GetDatetime";
import { LabelColumnAmount, InputColumnAmount } from "../../libs/Variables";

const TransactionEnquiry = () => {
  const context = useContext(AppContext);
  const [ErrorList, setErrorList] = useState([]);
  const [Username, setUsername] = useState("");
  const [SiteID, setSiteID] = useState("");
  const [JournalTypeLists, setJournalTypeLists] = useState();
  const [JournalType, setJournalType] = useState();
  const [TransactionDateFrom, setTransactionDateFrom] = useState(
    GetDatetime(true)
  );
  const [TransactionDateTo, setTransactionDateTo] = useState(GetDatetime(true));
  const [TransactionTableContent, setTransactionTableContent] = useState();

  if (!SiteID) {
    setSiteID(sessionStorage.getItem("siteID"));
  }
  if (!Username) {
    setUsername(context.usr);
  }

  const loadData = useCallback(async () => {
    if (SiteID && Username) {
      await trackPromise(
        Promise.all([
          axios
            .get(
              "/api/ReceiptLists/SetupTransactionEnquiry/" +
                SiteID +
                "/" +
                Username
            )
            .catch((e) => {
              apiError("apiUrl Get: ", e);
              var message = apiError("SetupTransactionEnquiry: ", e);
              setErrorList((errorList) => [...errorList, message]);
            }),
        ])
          .then((responses) => {
            if (responses[0] && responses[0] !== undefined) {
              setJournalTypeLists(responses[0].data[0].journalTypeLists);
              setJournalType(responses[0].data[0].defaultJournalType);
            } else {
              throw new Error("No Transaction Enquiry Data Found");
            }
          })
          .catch((e) => {
            var message = apiError("ReciptList: ", e);
            setErrorList((errorList) => [...errorList, message]);
          })
      );
    }
  }, [SiteID, Username]);

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [loadData]);

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name, theData);
    var pattAddedSuccess = new RegExp("AddedSuccess");
    var pattClearErrorList = new RegExp("ClearErrorList");
    if (pattAddedSuccess.test(name)) {
    } else if (pattClearErrorList.test(name)) {
      setErrorList(ErrorList.filter((item) => item !== theData));
    } else {
      setter(theData);
    }
  };

  const handleFormReset = (e) => {
    e.preventDefault();
    // resetForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTransactionTableContent();
    setErrorList([]);
    let Usr = Username;
    await trackPromise(
      Promise.all([
        axios
          .get(
            "/api/ReceiptLists/GetTransactionEnquiry/" +
              SiteID +
              "/" +
              JournalType +
              "/" +
              TransactionDateFrom +
              "/" +
              TransactionDateTo +
              "/" +
              Usr
          )
          .catch((e) => {
            apiError("apiUrl Get: ", e);
            var message = apiError("GetTransactionEnquiry: ", e);
            setErrorList((errorList) => [...errorList, message]);
          }),
      ])
        .then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            // console.log(responses[0].data);
            setTransactionTableContent(responses[0].data);
          }
          //  else {
          //   throw new Error("No Receipt List Line Data Found");
          // }
        })
        .catch((e) => {
          var message = apiError("GetTransactionEnquiry: ", e);
          setErrorList((errorList) => [...errorList, message]);
        })
    );
  };

  return (
    <div className="TransactionEnquiry">
      <h1>Transaction Enquiry</h1>
      {JournalTypeLists ? (
        <>
          <OutputMessage
            errorList={ErrorList}
            ClearErrorList={handleCallback()}
          />
          <Form id="NewReceiptListForm" onReset={handleFormReset}>
            <FormDropdown
              id="JournalType"
              Label="Journal Type"
              name="JournalType"
              placeholder={JournalType}
              itemContent={JournalTypeLists}
              value={handleCallback(setJournalType)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setErrorList)}
            />

            <FormDatePicker
              Label="Transaction Date From"
              name="TransactionDateFrom"
              placeholder={TransactionDateFrom}
              value={handleCallback(setTransactionDateFrom)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={2}
              errorList={handleCallback(setErrorList)}
              ShowBlank={false}
            />

            <FormDatePicker
              Label="Transaction Date To"
              name="TransactionDateTo"
              placeholder={TransactionDateTo}
              value={handleCallback(setTransactionDateTo)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={2}
              errorList={handleCallback(setErrorList)}
              ShowBlank={false}
            />

            <div className="FormGroup row">
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={8} className="IconBtn">
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmit}
                  name="Submit"
                >
                  <FaSave /> Continue
                </Button>

                {/* <Button variant="primary" onClick={handleFormReset}>
                    <FaTimesCircle /> Cancel
                  </Button> */}
              </Col>
            </div>
          </Form>
        </>
      ) : null}

      {TransactionTableContent ? (
        <TransactionEnquiryTable rows={TransactionTableContent} />
      ) : null}
    </div>
  );
};

export default TransactionEnquiry;
