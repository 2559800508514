import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { FaArrowCircleLeft } from "react-icons/fa";
import withRouter from "../libs/withRouter";

function NoAccess(props) {
  return (
    <div className="NoAccess">
      <h3>You do not have access to view this page.</h3>
      <h4>
        If you believe this to be in error then please contact your site
        administrator.
      </h4>

      <Row>
        <Col>
          <Link to="" onClick={() => props.navigate(-1)}>
            <FaArrowCircleLeft /> Back to previous page
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(NoAccess);
