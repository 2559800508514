import * as yup from "yup";

export function handleValidation(items) {
  return new Promise((resolve, reject) => {
    let name = items.name,
      data = items.data,
      schema = items.schema;

    yup
      .object()
      .shape({ [name]: schema })
      .validate(
        {
          [name]: data,
        },
        { abortEarly: true }
      )
      .then(() => {
        resolve(true);
      })
      .catch((e) => {
        // console.log(e);
        var message = {};
        message[name] = e.message;
        reject(message);
      });
  });
}
