import { Row, Col } from "react-bootstrap";
import TooltipActionButton from "./TooltipActionButton";

export function PaginationComponents(
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
  ShowAddNew
) {
  return (
    <>
      <Row className="pagination">
        <Col md={5}>
          <select
            className="btn btn-default btn-primary dropdown-toggle"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
        </Col>
        <Col md={"auto"}>
          <button
            className="btn btn-default btn-primary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"First"}
          </button>
          <button
            className="btn btn-default btn-primary"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"Back"}
          </button>
          <button
            className="btn btn-default btn-primary"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next"}
          </button>
          <button
            className="btn btn-default btn-primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {"Last"}
          </button>
        </Col>
        {ShowAddNew ? (
          <Col>
            <TooltipActionButton
              LinkTo={{
                pathname: `/NewUser`,
              }}
              ToolTipLabel="Add New User"
              Label="Add New"
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
}
