import React from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import withRouter from "../libs/withRouter";

function TooltipActionButton(props) {
  return (
    <div className="TooltipActionButton">
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="TooltipActionButtonTooltip" className="">
            {props.ToolTipLabel}
          </Tooltip>
        }
      >
        <Link to={props.LinkTo.pathname} state={props.LinkTo.state}>
          <Button variant="primary">
            <span>{props.Icon ? props.Icon : <FaPlus />}</span>
            <span>{props.Label}</span>
          </Button>
        </Link>
      </OverlayTrigger>
    </div>
  );
}
export default withRouter(TooltipActionButton);
