import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { ReceiptContext } from "../../libs/ReceiptContextLib";
import { DefaultLineItem } from "./DefaultLineItem";

const AddNewReceiptLine = (props) => {
  const Receipt = useContext(ReceiptContext);

  const ColumnAmount = Receipt.ReceiptHeaderItems.TaxType === "None" ? 8 : 11;

  const handleAddElement = (e) => {
    const lastObj = Receipt.RowContentList[Receipt.RowContentList.length - 1];
    let count = lastObj.ID;
    count++;
    Receipt.setRowContentList([
      ...Receipt.RowContentList,
      {
        ...DefaultLineItem(
          count,
          Receipt.ReceiptHeaderItems,
          Receipt.LineContent
        ),
      },
    ]);
  };

  const btnAdd = () => {
    return (
      <div className="btnAdd right">
        <Button block="true" variant="primary" onClick={handleAddElement}>
          <FaPlus />
        </Button>
      </div>
    );
  };

  return (
    <tr id={"RowElementAddNewLine"} role="row" className={"RowElement"}>
      {[...Array(ColumnAmount)].map((x, i) => (
        <td key={i} role="cell"></td>
      ))}
      <td role="cell">{btnAdd()}</td>
    </tr>
  );
};

export default AddNewReceiptLine;
