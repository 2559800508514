import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowCircleLeft, FaTimesCircle, FaSave } from "react-icons/fa";
import {
  CognitoIdentityProviderClient,
  AdminCreateUserCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import * as yup from "yup";
import { Row, Col, Form, Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import FormInputBox from "../Form/FormInputbox";
import OutputMessage from "../OutputMessage";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";
import { handleValidation } from "../handleValidation";
import UserListTable from "./UserListComponents/UserListTable";
import GetDatetime from "../../libs/GetDatetime";
import axios from "axios";
import apiError from "../../libs/apiError";
import { Auth } from "aws-amplify";

const schemaUsername = yup
    .string()
    .required("Username is required")
    .email("Must be a valid email address")
    .max(100, "Username must be less than 100 characters"),
  schemaFirstName = yup
    .string()
    .required("FirstName is required")
    .matches(inputRegex, "FirstName" + inputRegexMessage)
    .max(50, "FirstName must be less than 50 characters"),
  schemaLastName = yup
    .string()
    .required("LastName is required")
    .matches(inputRegex, "LastName" + inputRegexMessage)
    .max(50, "LastName must be less than 50 characters"),
  schemaPhoneNumber = yup
    .string()
    .required("PhoneNumber is required")
    .matches(
      /^(\+)[0-9]*$/,
      "PhoneNumber must be all numbers and begin with a Country Code e.g. +44"
    )
    .max(50, "PhoneNumber must be less than 50 characters");

const NewUser = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;

  const [Client, setClient] = useState();
  const [SiteID, setSiteID] = useState();
  const [SiteSettingsID, setSiteSettingsID] = useState();
  const [Username, setUsername] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const Password = "@Thepassword1";
  const [errorList, setErrorList] = useState([]);
  const [authErrorList, setAuthErrorList] = useState([]);
  const [validationErrorList, setValidationErrorList] = useState([]);
  const [PostFeedback, setPostFeedback] = useState();
  const [BankAccountList, setBankAccountList] = useState();
  const [JournalTypeLists, setJournalTypeLists] = useState();
  const [LocationList, setLocationList] = useState();
  const [PlaceholderListFilled, setPlaceholderListFilled] = useState(false);

  if (!SiteID) {
    setSiteID(sessionStorage.getItem("siteID"));
  }
  if (params !== undefined && params) {
    setPostFeedback(params.PostFeedback);
  }
  if (!SiteSettingsID) {
    setSiteSettingsID(sessionStorage.getItem("SiteSettingsID"));
  }

  const loadData = useCallback(async () => {
    if (SiteID) {
      await trackPromise(
        Promise.all([
          axios
            .get("/api/UserSites/GetInitialSettings/" + SiteID)
            .catch((e) => {
              var message = apiError("API User Settings Get: ", e);
              setErrorList((errorList) => [...errorList, message]);
            }),
        ]).then((responses) => {
          let placeholderArr;
          if (responses[0] && responses[0] !== undefined) {
            placeholderArr = responses[0].data[responses[0].data.length - 1];
          } else {
            throw new Error("Problem Fetching User Settings.");
          }

          setBankAccountList(placeholderArr.bankAccountList);
          setJournalTypeLists(placeholderArr.journalTypeLists);
          setLocationList(placeholderArr.locationList);

          let cognitoIdentityClient = new CognitoIdentityClient({
              region: process.env.REACT_APP_REGION,
            }),
            client = new CognitoIdentityProviderClient({
              region: process.env.REACT_APP_REGION,
              credentials: fromCognitoIdentityPool({
                client: cognitoIdentityClient,
                identityPoolId: Auth._config.identityPoolId,
              }),
            });
          setClient(client);

          setPlaceholderListFilled(true);
        })
      ).catch((e) => {
        var message = apiError("API Get: ", e);
        setErrorList((errorList) => [...errorList, message]);
      });
    }
  }, [SiteID]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleFormReset = (e) => {
    e.preventDefault();
    resetForm();
  };
  const resetForm = () => {
    navigate("/UserList");
  };

  const handleCallback = (setter) => (name, theData) => {
    var pattClearErrorList = new RegExp("ClearErrorList");
    if (pattClearErrorList.test(name)) {
      setErrorList(errorList.filter((item) => item !== theData));
    } else {
      setter(theData);
    }
  };

  const validateForm = async () => {
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "Username",
          data: Username,
          schema: schemaUsername,
        },
        {
          name: "FirstName",
          data: FirstName,
          schema: schemaFirstName,
        },
        {
          name: "LastName",
          data: LastName,
          schema: schemaLastName,
        },
        {
          name: "PhoneNumber",
          data: PhoneNumber,
          schema: schemaPhoneNumber,
        },
      ];
      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateForm()
      .then((response) => {
        // console.log(response);
        setErrorList([]);
        CheckIfUserExits()
          .then((ID) => {
            PostExistingUsr(ID);
          })
          .catch(() => {
            signUp();
          });
      })
      .catch((e) => {
        setValidationErrorList(JSON.stringify(e));
      });
  };

  const CheckIfUserExits = async () => {
    return new Promise(async (resolve, reject) => {
      await trackPromise(
        Promise.all([
          axios
            .get("/api/UserSites/CheckIfUserExits/" + Username)
            .catch((e) => {
              var message = apiError("API User Settings Get: ", e);
              reject(message);
            }),
        ]).then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            resolve(responses[0].data[0].userListID);
          }
        })
      ).catch((e) => {
        var message = apiError("API User Settings Get: ", e);
        reject(message);
      });
    });
  };

  const PostExistingUsr = async (UserListID) => {
    let DateTimeUpdated = GetDatetime();
    let DateTimeCreated = GetDatetime();

    let outputJSON = {
        UserListId: UserListID,
        SiteID: SiteID,
        SiteSettingsID: SiteSettingsID,
        UserName: Username,
        DateTimeUpdated: DateTimeUpdated,
        DateTimeCreated: DateTimeCreated,
      },
      theJournalList = SetListOutput(JournalTypeLists),
      theBankList = SetListOutput(BankAccountList),
      theLocationList = SetListOutput(LocationList);

    outputJSON["DefaultJournalType"] = theJournalList.DefaultItem;
    outputJSON["DefaultBank"] = theBankList.DefaultItem;
    outputJSON["PermissibleBanks"] = theBankList.PermissibleItems;
    outputJSON["DefaultLocation"] = theLocationList.DefaultItem;
    outputJSON["PermissibleLocations"] = theLocationList.PermissibleItems;

    // console.log(outputJSON);
    const postAPI = await "api/UserSites/PostExistingUsrToUserSite";

    trackPromise(
      axios
        .post(postAPI, outputJSON)
        .then((results) => {
          sessionStorage.setItem("UserNameState", JSON.stringify(Username));
          navigate("/UserSettings", {
            state: {
              NewUser: true,
              Username,
            },
          });
        })
        .catch((e) => {
          var message = apiError("PostAPI: ", e);
          setErrorList((errorList) => [...errorList, message]);
        })
    );
  };

  const signUp = async () => {
    let SiteName = sessionStorage.getItem("siteName");
    var params = {
      Username: Username,
      UserPoolId: Auth._config.userPoolId,
      TemporaryPassword: Password,
      DesiredDeliveryMediums: ["EMAIL"],
      // MessageAction: "RESEND",
      UserAttributes: [
        {
          Name: "custom:sitename",
          Value: SiteName,
        },
        {
          Name: "email",
          Value: Username,
        },
        {
          Name: "email_verified",
          Value: "true",
        },
        {
          Name: "phone_number",
          Value: PhoneNumber,
        },
        {
          Name: "phone_number_verified",
          Value: "true",
        },
      ],
    };

    var createUser = new AdminCreateUserCommand(params);
    await trackPromise(Client.send(createUser))
      .then(() => {
        // console.log(data);
        PostNewUser();
      })
      .catch((e) => {
        setAuthErrorList((errorList) => [...errorList, e.message]);
      });
  };

  const SetListOutput = (inputList) => {
    var PermissibleItemList = [];
    var o = {};
    inputList.forEach((element) => {
      if (element.default) {
        o["DefaultItem"] = element.id;
      }
      if (element.permissibleItem) {
        PermissibleItemList.push(element.id);
      }
    });
    o["PermissibleItems"] = PermissibleItemList.join(",");
    return o;
  };

  const PostNewUser = async () => {
    let DateTimeUpdated = GetDatetime();
    let DateTimeCreated = GetDatetime();

    let outputJSON = {
        SiteID: SiteID,
        FirstName: FirstName,
        LastName: LastName,
        PhoneNumber: PhoneNumber,
        SiteSettingsID: SiteSettingsID,
        UserName: Username,
        DateTimeUpdated: DateTimeUpdated,
        DateTimeCreated: DateTimeCreated,
      },
      theJournalList = SetListOutput(JournalTypeLists),
      theBankList = SetListOutput(BankAccountList),
      theLocationList = SetListOutput(LocationList);

    outputJSON["DefaultJournalType"] = theJournalList.DefaultItem;
    outputJSON["DefaultBank"] = theBankList.DefaultItem;
    outputJSON["PermissibleBanks"] = theBankList.PermissibleItems;
    outputJSON["DefaultLocation"] = theLocationList.DefaultItem;
    outputJSON["PermissibleLocations"] = theLocationList.PermissibleItems;

    // console.log(outputJSON);
    const postAPI = "api/UserSites/";

    trackPromise(
      axios
        .post(postAPI, outputJSON)
        .then((results) => {
          //   var postResponse = results.data;
          navigate("/UserSettings", {
            state: {
              NewUser: true,
              Username,
            },
          });
        })
        .catch((e) => {
          var message = apiError("PostAPI: ", e);
          setErrorList((errorList) => [...errorList, message]);
        })
    );
  };

  if (PlaceholderListFilled) {
    return (
      <div className="UserSettings">
        <h1>Add New User</h1>
        <Row>
          <Col>
            <Link to="/UserList">
              <FaArrowCircleLeft /> Back to User List
            </Link>
          </Col>
        </Row>

        <OutputMessage
          errorList={errorList}
          ClearErrorList={handleCallback()}
          PostFeedback={PostFeedback}
        />

        <div className="detailsContent">
          <Form
            id="UserSettingsForm"
            onSubmit={handleSubmit}
            onReset={handleFormReset}
          >
            <FormInputBox
              type="text"
              Label="Username (email address)"
              name="Username"
              placeholder={Username}
              value={handleCallback(setUsername)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={schemaUsername}
            />

            <FormInputBox
              type="text"
              Label="First Name"
              name="FirstName"
              placeholder={FirstName}
              value={handleCallback(setFirstName)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={schemaFirstName}
            />
            <FormInputBox
              type="text"
              Label="Last Name"
              name="LastName"
              placeholder={LastName}
              value={handleCallback(setLastName)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={schemaLastName}
            />
            <FormInputBox
              type="text"
              Label="Phone Number"
              name="PhoneNumber"
              placeholder={PhoneNumber}
              value={handleCallback(setPhoneNumber)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={schemaPhoneNumber}
            />

            <UserListTable
              id="BankAccountList"
              Label="Bank"
              name="BankAccountList"
              ItemContent={BankAccountList}
              NewItemContent={handleCallback(setBankAccountList)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              required={false}
              errorList={handleCallback(setValidationErrorList)}
            />

            <UserListTable
              id="JournalTypeLists"
              Label="Journal Type"
              name="JournalTypeLists"
              ItemContent={JournalTypeLists}
              NewItemContent={handleCallback(setJournalTypeLists)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              required={false}
              errorList={handleCallback(setValidationErrorList)}
            />

            <UserListTable
              id="LocationList"
              Label="Location"
              name="LocationList"
              ItemContent={LocationList}
              NewItemContent={handleCallback(setLocationList)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              required={false}
              errorList={handleCallback(setValidationErrorList)}
            />

            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={InputColumnAmount}>
                <p>
                  Please note that a temporary password is automatically
                  generated when you click save.
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={4} className="IconBtn">
                <Button variant="primary" type="submit">
                  <FaSave /> Save
                </Button>
                {authErrorList.length !== 0 ? (
                  <div className="invalid-tooltip">{authErrorList}</div>
                ) : null}
                {validationErrorList.length >= 1
                  ? ValidationError(JSON.stringify(validationErrorList))
                  : null}

                <Button variant="primary" onClick={handleFormReset}>
                  <FaTimesCircle /> Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
};

export default NewUser;
