import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";

const FormDatePicker = (props) => {
  const [ShowBlank, setShowBlank] = useState(props.ShowBlank);
  const [startDate, setStartDate] = useState(new Date(props.placeholder));

  useEffect(() => {
    setStartDate(new Date(props.placeholder));
  }, [props.placeholder]);

  const handleChange = (event) => {
    setStartDate(event);
    if (event) {
      let date = moment(event).format("YYYY-MM-DD");
      props.value(props.name, date);
    } else {
      props.value(props.name, null);
    }
  };

  const handleClick = (event) => {
    var NewDate = moment().toDate();
    setStartDate(NewDate);
    setShowBlank(false);
    props.value(props.name, NewDate);
  };

  const renderForm = () => {
    let name = props.name;
    return (
      <Form.Group className={"FormGroup"} as={Row} controlId={name}>
        <Form.Label column sm={props.LabelColumnAmount}>
          {props.Label}
        </Form.Label>
        <Col sm={props.InputColumnAmount}>
          <>
            {ShowBlank ? (
              <Button
                className="form-control EmptyButton"
                name={name}
                value={""}
                onClick={handleClick}
                disabled={props.disabled}
              />
            ) : (
              <DatePicker
                className="form-control"
                aria-describedby="inputGroupPrepend"
                selected={startDate}
                name={name}
                onChange={handleChange}
                disabled={props.disabled}
                placeholderText=""
                todayButton="Today"
                dateFormat="dd-MM-yyyy"
                onselectstart="return false"
                closeOnScroll={true}
              />
            )}

            {props.errorList[name] ? (
              <Form.Control.Feedback type="invalid" tooltip>
                <>{props.errorList[name]}</>
              </Form.Control.Feedback>
            ) : null}
          </>
        </Col>
      </Form.Group>
    );
  };

  return !props.hidden ? (
    props.tooltip ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={props.name + "Tooltip"} className="">
            {props.tooltip}
          </Tooltip>
        }
      >
        {renderForm()}
      </OverlayTrigger>
    ) : (
      renderForm()
    )
  ) : null;
};

export default FormDatePicker;
