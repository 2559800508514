import React, { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from "../../libs/contextLib";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Row, Col, Form, Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { FaTimesCircle, FaSave } from "react-icons/fa";
import FormInputBox from "../Form/FormInputbox";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
} from "../../libs/Variables";
import axios from "axios";
import apiError from "../../libs/apiError";
import OutputMessage from "../OutputMessage";
import { handleValidation } from "../handleValidation";
import { Encrypt } from "../../libs/Crypto";
import { v4 as uuidv4 } from "uuid";

const SiteNameSchema = yup
    .string()
    .required("SiteName is required")
    .max(100, "SiteName must be less than 100 characters"),
  CompanyIDSchema = yup
    .string()
    .required("Company ID is required")
    .max(100, "Company ID must be less than 100 characters"),
  UserIDSchema = yup
    .string()
    .required("User ID is required")
    .max(250, "User ID must be less than 250 characters"),
  UserPasswordSchema = yup
    .string()
    .required("User Password is required")
    .max(70, "User Password must be less than 70 characters");

const NewSite = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;

  const [PlaceholderListFilled, setPlaceholderListFilled] = useState(false);
  const [CompanyID, setCompanyID] = useState();
  const [NewSiteID, setNewSiteID] = useState();
  const [ErrorList, setErrorList] = useState([]);
  const [ValidationErrorList, setValidationErrorList] = useState([]);
  const [Username, setUsername] = useState();
  const [PostFeedback, setPostFeedback] = useState();
  const [SiteName, setSiteName] = useState();
  const [UserID, setUserID] = useState();
  const [UserPassword, setUserPassword] = useState();

  if (!Username) {
    setUsername(context.usr);
  }

  const loadData = useCallback(async () => {
    if (params !== undefined && params) {
      setPostFeedback(params.PostFeedback);
    }

    if (Username) {
      setNewSiteID(uuidv4());
      setPlaceholderListFilled(true);
    }
  }, [Username, params]);

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [loadData]);

  const handleFormReset = (e) => {
    e.preventDefault();
    resetForm();
  };
  const resetForm = useCallback(async () => {
    loadData();
  }, [loadData]);

  const handleCallback = (setter) => (name, theData) => {
    var pattUserPassword = new RegExp("UserPassword"),
      pattErrorList = new RegExp("errorList"),
      pattClearErrorList = new RegExp("ClearErrorList");
    if (pattUserPassword.test(name)) {
      setter(Encrypt(theData, NewSiteID));
    } else if (pattErrorList.test(name)) {
      setValidationErrorList((errorList) => [...errorList, theData]);
    } else if (pattClearErrorList.test(name)) {
      if (name === "ClearValidationErrorList") {
        setValidationErrorList([]);
      } else {
        setErrorList(ErrorList.filter((item) => item !== theData));
      }
    } else {
      setter(theData);
    }
  };

  const validateForm = () => {
    setValidationErrorList([]);
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "SiteName",
          data: SiteName,
          schema: SiteNameSchema,
        },
        {
          name: "CompanyID",
          data: CompanyID,
          schema: CompanyIDSchema,
        },
        {
          name: "UserID",
          data: UserID,
          schema: UserIDSchema,
        },
        {
          name: "UserPassword",
          data: UserPassword,
          schema: UserPasswordSchema,
        },
      ];

      var Status = [];
      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm()
      .then((response) => {
        setValidationErrorList([]);
        setErrorList([]);
        doSubmit();
      })
      .catch((e) => {
        setValidationErrorList((errorList) => [...errorList, e]);
      });
  };

  const doSubmit = async () => {
    if (!Username) {
      throw new Error("No Username set.");
    }

    let outputJSON = {
      SiteID: NewSiteID,
      IsActive: true,
      SiteName: SiteName,
      IntacctCompanyID: CompanyID,
      UserID: UserID,
      UserPassword: UserPassword,
    };

    // console.log(outputJSON);
    await trackPromise(
      axios
        .post("api/SiteSettings/", outputJSON)
        .then((responses) => {
          if (responses) {
            console.log(responses);
            // resetForm();
            navigate("/SetCompany", {
              state: {
                NewSiteID,
              },
            });
          }
        })
        .catch((e) => {
          var message = apiError("PostAPI: ", e);
          setErrorList((ErrorList) => [...ErrorList, message]);
        })
    );
  };

  const renderContent = () => {
    return (
      <div className="NewSite">
        <h1>New Site</h1>

        <OutputMessage errorList={ErrorList} PostFeedback={PostFeedback} />

        <div className="detailsContent">
          <Form id="NewSiteForm" onReset={handleFormReset} autoComplete="off">
            <FormInputBox
              type="text"
              Label="Site Name"
              name="SiteName"
              placeholder={SiteName}
              value={handleCallback(setSiteName)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={SiteNameSchema}
              autoComplete="__away"
            />

            <FormInputBox
              type="text"
              Label="Company ID"
              name="CompanyID"
              placeholder={CompanyID}
              value={handleCallback(setCompanyID)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={CompanyIDSchema}
              autoComplete="__away"
            />

            <FormInputBox
              type="text"
              Label="User ID"
              name="UserID"
              placeholder={UserID}
              value={handleCallback(setUserID)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={UserIDSchema}
              autoComplete="__away"
            />

            <FormInputBox
              type="password"
              Label="User Password"
              name="UserPassword"
              placeholder={UserPassword}
              value={handleCallback(setUserPassword)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={UserPasswordSchema}
              autoComplete="__away"
            />

            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={4} className="IconBtn">
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmit}
                  name="Submit"
                >
                  <FaSave /> Continue
                </Button>

                {ValidationErrorList.length >= 1 ? (
                  <>
                    {console.log(ValidationErrorList)}
                    <ValidationError
                      errorListState={ValidationErrorList}
                      ClearErrorList={handleCallback(setValidationErrorList)}
                    />
                  </>
                ) : null}

                <Button variant="primary" onClick={handleFormReset}>
                  <FaTimesCircle /> Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  };

  if (!PlaceholderListFilled) {
    return null;
  } else {
    return renderContent();
  }
};

export default NewSite;
