import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./Pages/NotFound";
import NoAccess from "./Pages/NoAccess";
import Login from "./Pages/Login";
// import CompanySettings from "./Pages/CompanySettings";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ResetPassword from "./components/Login/ResetPassword";
import CompanyListDetails from "./components/CompanySettings/CompanyListDetails";
// import CompanyListAddNew from "./components/CompanySettings/CompanyListAddNew";
import NewUser from "./components/UserList/NewUser";
import UserSettings from "./components/UserList/UserSettings";
import NewSite from "./components/SiteSettings/NewSite";
import SetCompany from "./components/SiteSettings/SetCompany";
import SiteSettings from "./Pages/SiteSettings";
import UserList from "./Pages/UserList";
import ReceiptList from "./Pages/ReceiptList";
import TransactionEnquiry from "./components/TransactionEnquiry/TransactionEnquiry";

import { AppContext } from "./libs/contextLib";

const RouteList = () => {
  const context = useContext(AppContext);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedRoute exact path="/">
            <ReceiptList />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/ReceiptList"
        element={
          <AuthenticatedRoute exact path="/ReceiptList">
            <ReceiptList />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/TransactionEnquiry"
        element={
          <AuthenticatedRoute exact path="/TransactionEnquiry">
            <TransactionEnquiry />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/SiteSettings"
        element={
          <AuthenticatedRoute exact path="/SiteSettings">
            <SiteSettings />
          </AuthenticatedRoute>
        }
      />

      {/* <Route
          path="/CompanySettings"
          element={
            <AuthenticatedRoute exact path="/CompanySettings">
              <CompanySettings />
            </AuthenticatedRoute>
          }
        /> */}
      <Route
        path="/CompanyListDetails"
        element={
          <AuthenticatedRoute exact path="/CompanyListDetails">
            <CompanyListDetails />
          </AuthenticatedRoute>
        }
      />
      {/* <Route
          path="/CompanyListAddNew"
          element={
            <AuthenticatedRoute exact path="/CompanyListAddNew">
              <CompanyListAddNew />
            </AuthenticatedRoute>
          }
        /> */}

      <Route
        path="/UserList"
        element={
          <AuthenticatedRoute exact path="/UserList">
            <UserList />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/UserSettings"
        element={
          <AuthenticatedRoute exact path="/UserSettings">
            <UserSettings />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/NewUser"
        element={
          <AuthenticatedRoute exact path="/NewUser">
            <NewUser />
          </AuthenticatedRoute>
        }
      />

      {context.SettingsList.IsSuperAdmin ? (
        <>
          <Route path="/NewSite" element={<NewSite />} />
          <Route path="/SetCompany" element={<SetCompany />} />
        </>
      ) : null}

      <Route
        path="/NoAccess"
        element={
          <AuthenticatedRoute exact path="/NoAccess">
            <NoAccess />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/Login"
        element={
          <UnauthenticatedRoute exact path="/Login">
            <Login />
          </UnauthenticatedRoute>
        }
      />
      <Route path="/ResetPassword" element={<ResetPassword />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteList;
