import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const FormDropdown = (props) => {
  const name = props.name;
  var index = props.itemContent.findIndex(
    (obj) => obj.id === props.placeholder
  );
  if (index === -1) index = 0;

  const [singleSelections, setSingleSelections] = useState([
    props.itemContent[index],
  ]);

  const handleChange = (e) => {
    setSingleSelections(e);
    if (e[0]) {
      var index = props.itemContent.findIndex((obj) => obj.id === e[0].id);
      props.value(props.name, props.itemContent[index].id);
    }
  };

  useEffect(() => {
    var index = props.itemContent.findIndex(
      (obj) => obj.id === props.placeholder
    );
    if (index === -1) index = 0;
    setSingleSelections([props.itemContent[index]]);
  }, [props.itemContent, props.placeholder]);

  const filterBy = (option, state) => {
    if (state.selected.length) {
      return true;
    }
    return option.content.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
  };

  const List = () => {
    if (props.itemContent.length === 0) {
      return (
        <div className="invalid-tooltip typeError">
          Issue fetching List Content - Please check the Settings pages.
        </div>
      );
    }
    return (
      <Typeahead
        className="Typeahead"
        id={props.id}
        disabled={props.disabled}
        filterBy={filterBy}
        labelKey="content"
        onChange={handleChange}
        options={props.itemContent}
        selected={singleSelections}
        flip={true}
      />
    );
  };

  if (props.Label) {
    return !props.hidden ? (
      <Form.Group className={"FormGroup"} as={Row} controlId={props.id}>
        <Form.Label column sm={props.LabelColumnAmount}>
          {props.Label}
        </Form.Label>
        <Col sm={props.InputColumnAmount}>
          {List()}
          {props.errorList[name] ? (
            <Form.Control.Feedback type="invalid" tooltip>
              <>{props.errorList[name]}</>
            </Form.Control.Feedback>
          ) : null}
        </Col>
      </Form.Group>
    ) : null;
  } else {
    return (
      <Form.Group className={"FormGroup"} controlId={props.id}>
        {List()}
      </Form.Group>
    );
  }
};

export default FormDropdown;
