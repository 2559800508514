import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import CheckIfValueReturned from "../../libs/CheckIfValueReturned";
import { Table } from "react-bootstrap";
import { PaginationComponents } from "../PaginationComponents";
import { SetObserver } from "../../libs/Observer";

const UserListTable = (props) => {
  let navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Is Active?",
        accessor: "isActive",
      },
    ],
    []
  );

  const data = React.useMemo(
    () =>
      props.rows.map((Element, i) => {
        return {
          userName: Element.userName,
          firstName: Element.firstName,
          lastName: Element.lastName,
          isActive: CheckIfValueReturned(Element.isActive),
        };
      }),
    [props.rows]
  );

  useEffect(() => {
    SetObserver();
  }, []);

  return (
    <TheTable
      columns={columns}
      data={data}
      getRowProps={(row) => ({
        onClick: () => {
          const Username = row.values.userName;
          navigate("/UserSettings", {
            state: { Username },
          });
        },
      })}
    />
  );
};

const defaultPropGetter = () => ({});
const TheTable = ({
  columns,
  data,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );
  let ShowAddNew = true;

  return (
    <div className="UserListTable">
      <div className="react-table">
        <Table hover size="sm" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={column.id === "isActive" ? "center" : null}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={
                          cell.column.id === "isActive" ? "center" : null
                        }
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                          },
                          getColumnProps(cell.column),
                          getCellProps(cell),
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {PaginationComponents(
          canPreviousPage,
          canNextPage,
          pageOptions,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          ShowAddNew
        )}
      </div>
    </div>
  );
};

export default UserListTable;
