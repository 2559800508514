export const DefaultLineItem = (ID, ReceiptHeaderItems, LineContent) => {
  var Rate =
    ReceiptHeaderItems.TaxType === "None"
      ? 0
      : LineContent.taxDetailList[0].value;
  var TotalIncTax =
    parseFloat(ReceiptHeaderItems.Amount) +
    (parseFloat(ReceiptHeaderItems.Amount) * Rate) / 100;
  var TaxAmount = (parseFloat(ReceiptHeaderItems.Amount) * Rate) / 100;

  return {
    ID,
    ItemCode: 0,
    Class: 0,
    Department: 0,
    Memo: "",
    TaxDetails: 0,
    Rate: Rate.toFixed(2),
    TaxAmount: TaxAmount.toFixed(2),
    DebitSelected: ID === 1 ? ReceiptHeaderItems.receiptsType : false,
    CreditSelected: ID === 1 ? ReceiptHeaderItems.paymentsType : false,
    Debit: ReceiptHeaderItems.receiptsType
      ? parseFloat(ID === 1 ? ReceiptHeaderItems.Amount : 0).toFixed(2)
      : parseFloat(0).toFixed(2),
    Credit: ReceiptHeaderItems.paymentsType
      ? parseFloat(ID === 1 ? ReceiptHeaderItems.Amount : 0).toFixed(2)
      : parseFloat(0).toFixed(2),
    TotalDebit: ReceiptHeaderItems.receiptsType
      ? parseFloat(ID === 1 ? TotalIncTax : 0).toFixed(2)
      : parseFloat(0).toFixed(2),
    TotalCredit: ReceiptHeaderItems.paymentsType
      ? parseFloat(ID === 1 ? TotalIncTax : 0).toFixed(2)
      : parseFloat(0).toFixed(2),
  };
};
