import React, { useEffect, useState, useMemo, useContext } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AppContext } from "../../../libs/contextLib";
import { useTable, usePagination, useSortBy } from "react-table";
import { FaTimesCircle, FaSave } from "react-icons/fa";
import { Table } from "react-bootstrap";
import { PaginationComponents } from "../../PaginationComponents";
import { Row, Col, Button } from "react-bootstrap";
import { SetObserver } from "../../../libs/Observer";
import OverlayFormatter from "../../../libs/OverlayFormatter";
import FormCheck from "../../Form/FormCheck";
import ListSort from "../../../libs/ListSort";
import {
  LabelColumnAmount,
  InputColumnAmount,
  // ValidationError,
} from "../../../libs/Variables";

const UserListTablePopup = (props) => {
  const context = useContext(AppContext);
  // const [validationErrorList, setvalidationErrorList] = useState([]);
  const [RowData, setRowData] = useState(props.rowData);
  let IsAdmin = context.SettingsList.IsAdmin;
  let columns = useMemo(() => {
    if (IsAdmin) {
      if (props.name === "JournalTypeLists") {
        return [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Name",
            accessor: "content",
            Cell: (CellContent) => {
              return OverlayFormatter(CellContent.value);
            },
          },
          {
            Header: "Is Default?",
            accessor: "default",
            Cell: (cell) => {
              return (
                <FormCheck
                  name="default"
                  CheckAlone
                  id={cell.row.original.id}
                  initialState={RowData[cell.row.id].default}
                  value={() => {}}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                />
              );
            },
          },
        ];
      } else {
        return [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Name",
            accessor: "content",
            Cell: (CellContent) => {
              return OverlayFormatter(CellContent.value);
            },
          },
          {
            Header: "Is Permissible?",
            accessor: "permissibleItem",
            Cell: (cell) => {
              return (
                <FormCheck
                  name="permissibleItem"
                  CheckAlone
                  id={cell.row.original.id}
                  initialState={RowData[cell.row.id].permissibleItem}
                  value={() => {}}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                />
              );
            },
          },
          {
            Header: "Is Default?",
            accessor: "default",
            Cell: (cell) => {
              return (
                <FormCheck
                  name="default"
                  CheckAlone
                  id={cell.row.original.id}
                  initialState={RowData[cell.row.id].default}
                  value={() => {}}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                />
              );
            },
          },
        ];
      }
    } else {
      return [
        {
          Header: "ID",
          accessor: "id",
        },
        {
          Header: "Name",
          accessor: "content",
          Cell: (CellContent) => {
            return OverlayFormatter(CellContent.value);
          },
        },
        {
          Header: "Is Default?",
          accessor: "default",
          Cell: (cell) => {
            return (
              <FormCheck
                name="default"
                CheckAlone
                id={cell.row.original.id}
                initialState={RowData[cell.row.id].default}
                value={() => {}}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
              />
            );
          },
        },
      ];
    }
  }, [IsAdmin, RowData, props.name]);

  useEffect(() => {
    SetObserver();
  }, []);

  return (
    <Popup
      className="UserListTablePopup"
      trigger={<Button variant="primary">Edit {props.buttonLabel}</Button>}
      modal
      nested
      closeOnDocumentClick={false}
    >
      {(Close) => (
        <div className="Modal">
          <button
            className="close"
            onClick={() => {
              setRowData(props.rowData);
              Close();
            }}
          >
            <FaTimesCircle />
          </button>
          <div className="content">
            <TheTable
              columns={columns}
              data={RowData}
              getCellProps={(cell) => ({
                onClick: () => {
                  let RowID = cell.row.allCells[0].value;
                  let ColumnName = cell.column.id;
                  if (ColumnName === "permissibleItem") {
                    setRowData(
                      RowData.map((Element) => {
                        if (Element.id === RowID) {
                          var r = {
                            ...Element,
                            permissibleItem: !Element.permissibleItem,
                          };
                          if (
                            Element.default === true &&
                            props.name !== "JournalTypeLists"
                          ) {
                            r["permissibleItem"] = true;
                          }
                          return r;
                        }
                        return {
                          ...Element,
                        };
                      })
                    );
                  }
                  if (ColumnName === "default") {
                    setRowData(
                      RowData.map((Element) => {
                        if (Element.id === RowID) {
                          var r = { ...Element, default: true };
                          if (props.name !== "JournalTypeLists") {
                            r["permissibleItem"] = true;
                          }
                          return r;
                        }
                        return {
                          ...Element,
                          default: false,
                        };
                      })
                    );
                  }
                },
              })}
            />
            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={4} className="IconBtn">
                {/* {validationErrorList.length >= 1
                  ? ValidationError(validationErrorList)
                  : null} */}
                <Button
                  variant="primary"
                  onClick={() => {
                    // let ErrorFound = false;

                    // RowData.forEach((element) => {
                    //   if (element.default && !element.permissibleItem) {
                    //     ErrorFound = true;
                    //   }
                    // });

                    // if (validationErrorList.length <= 0 && !ErrorFound) {
                    props.ValuesToUpdate("Save", ListSort(RowData));
                    Close();
                    // } else {
                    //   setvalidationErrorList((validationErrorList) => [
                    //     ...validationErrorList,
                    //     `default Element has not been set as Permissible.`,
                    //   ]);
                    // }
                  }}
                  type="submit"
                >
                  <FaSave /> Save
                </Button>

                <Button
                  variant="primary"
                  onClick={() => {
                    setRowData(props.rowData);
                    Close();
                  }}
                >
                  <FaTimesCircle /> Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Popup>
  );
};

const defaultPropGetter = () => ({});
const TheTable = ({
  columns,
  data,

  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const {
    prepareRow,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: ["id"],
        sortBy: [
          {
            id: "content",
          },
        ],
      },
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <div className="react-table">
      <Table hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {
                    ...column.getHeaderProps(/*column.getSortByToggleProps()*/)
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {PaginationComponents(
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageIndex,
        pageSize
      )}
    </div>
  );
};

export default UserListTablePopup;
