export default function apiError(location, e) {
  if (e.response) {
    if (e.response) {
      if (isObject(e.response.data)) {
        e = JSON.stringify(e.response.data);
        return e;
      } else {
        if (e.response.data) {
          return e.response.data;
        } else {
          return e.message;
        }
      }
    } else {
      return e.message;
    }
  } else {
    if (e.message !== "Cannot read property 'data' of undefined") {
      return e.message;
    } else {
      return null;
    }
  }
}

function isObject(val) {
  if (val === null) {
    return false;
  }
  return typeof val === "function" || typeof val === "object";
}
