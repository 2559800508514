import React, { useState, createContext } from "react";

export const ReceiptContext = createContext({
  setReceiptHeaderItems: () => {},
  setLineContent: () => {},
  setRowDataResult: () => {},
  setReceiptLineItems: () => {},
  setRowContentList: () => {},
  setGrandTotalDebit: () => {},
  setGrandTotalCredit: () => {},
});

export const ReceiptProvider = (props) => {
  const [ReceiptHeaderItems, setReceiptHeaderItems] = useState("");
  const [LineContent, setLineContent] = useState("");
  const [RowDataResult, setRowDataResult] = useState("");
  const [ReceiptLineItems, setReceiptLineItems] = useState("");
  const [RowContentList, setRowContentList] = useState([]);
  const [GrandTotalDebit, setGrandTotalDebit] = useState(0);
  const [GrandTotalCredit, setGrandTotalCredit] = useState(0);

  let state = {
    ReceiptHeaderItems,
    setReceiptHeaderItems,
    LineContent,
    setLineContent,
    RowDataResult,
    setRowDataResult,
    ReceiptLineItems,
    setReceiptLineItems,
    RowContentList,
    setRowContentList,
    GrandTotalDebit,
    setGrandTotalDebit,
    GrandTotalCredit,
    setGrandTotalCredit,
  };

  return (
    <ReceiptContext.Provider value={state}>
      {props.children}
    </ReceiptContext.Provider>
  );
};

export const ReceiptConsumer = ReceiptContext.Consumer;
export default ReceiptContext;
