import Rijndael from "rijndael-js";
const padder = require("pkcs7-padding");
let Buffer = require("buffer/").Buffer;
let key;
let iv;

function setConfig(SiteID) {
  //Set key - remove all hyphans from siteID and pad with 1s so it is the required 32 charcters in length
  if (sessionStorage.getItem("siteID") && !SiteID) {
    var theKey = sessionStorage
      .getItem("siteID")
      .replace(/-/g, "")
      .padEnd(32, 1);
  }
  if (SiteID) {
    theKey = SiteID.replace(/-/g, "").padEnd(32, 1);
  }
  key = Buffer.from(theKey, "utf8");

  //Set IV string - remove all non-numeric data, get the first 16 characters and pad to with 1s so that it is the requred 16 charcters in length
  let setIV = theKey.substring(0, 16).padEnd(16, 1);
  iv = Buffer.from(setIV, "utf8");
}

export function Encrypt(text, SiteID) {
  setConfig(SiteID);
  const theText = Buffer.from(text, "utf8");
  const padded = padder.pad(theText, 32);
  const cipher = new Rijndael(key, "cbc");
  const encrypted = Buffer.from(cipher.encrypt(padded, 128, iv), "utf8");
  return encrypted.toString("base64");
}

export function Decrypt(text) {
  try {
    setConfig();
    var theText = Uint8Array.from(atob(text), (c) => c.charCodeAt(0));
    const decipher = new Rijndael(key, "cbc");
    const decryptedPadded = Buffer.from(decipher.decrypt(theText, 128, iv));
    const decrypted = padder.unpad(decryptedPadded, 32);
    return decrypted.toString();
  } catch (e) {
    return text;
  }
}
