export function SetObserver() {
  let table = document.querySelector(" .table thead tr");
  if (table !== null) {
    let observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle("isPinned", e.intersectionRatio < 1),
      { threshold: [1] }
    );
    return observer.observe(table);
  }
}
