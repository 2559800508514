import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { FaArrowCircleLeft } from "react-icons/fa";
import OutputMessage from "../components/OutputMessage";

const IsError = (props) => {
  const navigate = useNavigate();

  const [ErrorList, setErrorList] = useState(props.errorList);

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name, theData);
    var pattClearErrorList = new RegExp("ClearErrorList");
    if (pattClearErrorList.test(name)) {
      setErrorList(ErrorList.filter((item) => item !== theData));
    }
  };
  return (
    <>
      <Row>
        <Col>
          {ErrorList ? (
            <>
              <OutputMessage
                errorList={ErrorList}
                ClearErrorList={handleCallback()}
              />
            </>
          ) : (
            <p>No data found</p>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="Link" onClick={() => navigate(-1)}>
            <FaArrowCircleLeft /> Back to previous page
          </div>
        </Col>
      </Row>
    </>
  );
};

export default IsError;
