import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { AppContext } from "../libs/contextLib";
import { ReceiptProvider } from "../libs/ReceiptContextLib";
import apiError from "../libs/apiError";
import { trackPromise } from "react-promise-tracker";
import NewReceiptList from "../components/ReceiptList/NewReceiptList";
import OutputMessage from "../components/OutputMessage";

const ReceiptList = () => {
  const context = useContext(AppContext);
  const [errorList, setErrorList] = useState([]);
  const [SettingsData, setSettingsData] = useState();
  const [Username, setUsername] = useState("");
  const [SiteID, setSiteID] = useState("");

  if (!SiteID) {
    setSiteID(sessionStorage.getItem("siteID"));
  }
  if (!Username) {
    setUsername(context.usr);
  }

  const loadData = useCallback(async () => {
    if (SiteID && Username) {
      await trackPromise(
        Promise.all([
          axios
            .get("/api/ReceiptLists/GetReceiptList/" + SiteID + "/" + Username)
            .catch((e) => {
              apiError("apiUrl Get: ", e);
              var message = apiError("GetReceiptList: ", e);
              setErrorList((errorList) => [...errorList, message]);
            }),
        ])
          .then((responses) => {
            if (responses[0] && responses[0] !== undefined) {
              setSettingsData({ ...responses[0].data[0], SiteID });
            } else {
              throw new Error("No Receipt List Data Found");
            }
          })
          .catch((e) => {
            var message = apiError("ReciptList: ", e);
            setErrorList((errorList) => [...errorList, message]);
          })
      );
    }
  }, [SiteID, Username]);

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [loadData]);

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name, theData);
    var pattClearErrorList = new RegExp("ClearErrorList");
    if (pattClearErrorList.test(name)) {
      setErrorList(errorList.filter((item) => item !== theData));
    }
  };

  return (
    <div className="ReceiptList">
      <h1>Receipt Entry</h1>
      {SettingsData ? (
        <>
          <div className="right">
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                window.open("/TransactionEnquiry", "_blank");
              }}
            >
              Transaction Enquiry
            </Button>
          </div>

          <OutputMessage
            errorList={errorList}
            ClearErrorList={handleCallback()}
          />
          <ReceiptProvider>
            <NewReceiptList SettingsData={SettingsData} />
          </ReceiptProvider>
        </>
      ) : null}
    </div>
  );
};

export default ReceiptList;
