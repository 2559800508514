import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return !this.props.hidden ? (
      <Form.Group className={"FormGroup"} as={Row} controlId={this.props.name}>
        <Form.Label column sm={this.props.LabelColumnAmount}>
          {this.props.Label}
        </Form.Label>
        <Col sm={this.props.InputColumnAmount}>{this.props.content}</Col>
      </Form.Group>
    ) : null;
  }
}

export default FormContainer;
