import React, { Fragment, useEffect, useMemo } from "react";
import { useTable, usePagination, useExpanded } from "react-table";
import { Table } from "react-bootstrap";
import { SetObserver } from "../../libs/Observer";
import { PaginationComponents } from "../PaginationComponents";
import TransactionLineRow from "./TransactionLineRow";
import TransactionsAttachments from "./TransactionsAttachments";

const TransactionEnquiryTable = (props) => {
  const columns = useMemo(
    () => [
      { Header: "Date", accessor: "postingDate" },
      { Header: "Description", accessor: "description" },
      { Header: "Location", accessor: "location" },
      { Header: "Reference", accessor: "reference" },
      { Header: "Entered By", accessor: "createdBy" },
    ],
    []
  );
  const data = useMemo(() => props.rows, [props.rows]);

  useEffect(() => {
    SetObserver();
  }, []);

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        <TransactionLineRow Content={data[row.id].receiptListLines} />
        <TransactionsAttachments
          Content={data[row.id].receiptListAttachments}
        />
      </>
    ),
    [data]
  );

  return (
    <TheTable
      columns={columns}
      data={data}
      renderRowSubComponent={renderRowSubComponent}
    />
  );
};

const defaultPropGetter = () => ({});
const TheTable = ({
  columns,
  data,
  renderRowSubComponent,
  getRowProps = defaultPropGetter,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    toggleAllRowsExpanded,
    prepareRow,
    visibleColumns,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useExpanded,
    usePagination
  );

  return (
    <div className="TransactionEnquiryTable">
      <div className="react-table">
        <Table hover size="sm" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={i}>
                  <tr
                    {...row.getRowProps(getRowProps(row))}
                    onClick={() => {
                      toggleAllRowsExpanded(false);
                      row.toggleRowExpanded(!row.isExpanded);
                    }}
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <td key={i} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
        {PaginationComponents(
          canPreviousPage,
          canNextPage,
          pageOptions,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize
        )}
      </div>
    </div>
  );
};

export default TransactionEnquiryTable;
