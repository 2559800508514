import { OverlayTrigger, Tooltip } from "react-bootstrap";

const OverlayFormatter = (cellContent, label, placement) => {
  return (
    <OverlayTrigger
      placement={!placement ? "top" : placement}
      overlay={
        <Tooltip id="ReferenceTooltip" className="">
          {label ? label : cellContent}
        </Tooltip>
      }
    >
      <span>{cellContent}</span>
    </OverlayTrigger>
  );
};

export default OverlayFormatter;
