import React, { useState } from "react";
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { handleValidation } from "../handleValidation";
const DOMPurify = require("dompurify");

const FormInputBox = (props) => {
  const [data, setdata] = useState(props.placeholder);
  const [errorList, seterrorList] = useState();
  const [name] = useState(props.name);

  const FormatValue = (value) => {
    value = value.trimStart();
    let cleanedValue = DOMPurify.sanitize(value);
    if (props.ConvertToUppercase) {
      cleanedValue = cleanedValue.toUpperCase();
    }
    return cleanedValue;
  };

  const handleChange = (e) => {
    let cleanedValue = FormatValue(e.target.value);
    setdata(cleanedValue);
    props.value(props.name, cleanedValue);
  };

  const handleBlur = (e) => {
    let cleanedValue = FormatValue(e.target.value);
    if (props.IsCurrency) {
      if (cleanedValue === "" || isNaN(cleanedValue)) {
        cleanedValue = 0;
      }
      cleanedValue = parseFloat(cleanedValue).toFixed(2);
    }
    if (props.schema) {
      let items = {
        name: props.name,
        data: cleanedValue,
        schema: props.schema,
      };
      handleValidation(items)
        .then(() => {
          seterrorList("");

          setdata(cleanedValue);
          props.value(props.name, cleanedValue);
        })
        .catch((e) => {
          seterrorList(e);
          if (typeof props.errorList === "object") {
            props.errorList.push(e[name]);
          } else {
            props.errorList(name, e);
          }
        });
    }
  };

  const StandardInput = () => {
    return (
      <Form.Control
        type={props.type}
        aria-describedby="inputGroupPrepend"
        name={name}
        value={data || ""}
        placeholder={props.Instructions}
        onChange={handleChange}
        onBlur={handleBlur}
        required={props.required}
        disabled={props.disabled}
        autoComplete={props.autoComplete ? props.autoComplete : null}
      />
    );
  };

  const renderForm = () => {
    return props.Label ? (
      <>
        <Form.Group className={"FormGroup"} as={Row} controlId={name}>
          <Form.Label column sm={props.LabelColumnAmount}>
            {props.Label}
          </Form.Label>
          <Col sm={props.InputColumnAmount}>
            {props.type === "password" ? (
              <>
                <input
                  type="text"
                  value={""}
                  style={{ display: "none" }}
                  readOnly
                />

                <Form.Control
                  type="password"
                  aria-describedby="inputGroupPrepend"
                  name={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={props.required}
                  disabled={props.disabled}
                  autoComplete={props.autoComplete ? props.autoComplete : null}
                />
              </>
            ) : (
              StandardInput()
            )}
          </Col>
          {errorList && errorList[name] ? (
            <Col>
              <div className="invalid-tooltip typeError">{errorList[name]}</div>
            </Col>
          ) : null}
          {!errorList && props.errorList[name] ? (
            <Col>
              <div className="invalid-tooltip typeError">
                {props.errorList[name]}
              </div>
            </Col>
          ) : null}
        </Form.Group>
      </>
    ) : (
      <Form.Group className={"FormGroup"} controlId={name}>
        {StandardInput()}
        {errorList && errorList[name] ? (
          <div className="invalid-tooltip typeError">{errorList[name]}</div>
        ) : null}
        {!errorList && props.errorList[name] ? (
          <div className="invalid-tooltip typeError">
            {props.errorList[name]}
          </div>
        ) : null}
      </Form.Group>
    );
  };

  return !props.hidden ? (
    props.tooltip ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={props.name + "Tooltip"} className="">
            {props.tooltip}
          </Tooltip>
        }
      >
        {renderForm()}
      </OverlayTrigger>
    ) : (
      renderForm()
    )
  ) : null;
};

export default FormInputBox;
