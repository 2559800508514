import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import * as yup from "yup";
import { Row, Col, Form, Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { FaTimesCircle, FaSave } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../libs/contextLib";
import apiError from "../../libs/apiError";
import FormInputBox from "../Form/FormInputbox";
import FormDropdown from "../Form/FormDropdown";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";
import { handleValidation } from "../handleValidation";
import OutputMessage from "../OutputMessage";
const ItemFilterNameSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, inputRegexMessage)
    .max(100, "Must be less than 100 characters"),
  ItemFilterValueSchema = yup
    .string()
    .nullable()
    .matches(inputRegex, inputRegexMessage)
    .max(100, "Must be less than 100 characters"),
  OrderEntryTransactionDefinitionSchema = yup
    .string()
    .required("Required")
    .nullable()
    .matches(inputRegex, inputRegexMessage)
    .max(100, "Must be less than 100 characters");

const SetCompany = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;
  const [SiteID, setSiteID] = useState();
  const [ErrorList, setErrorList] = useState([]);
  const [ValidationErrorList, setValidationErrorList] = useState([]);
  const [CompanyList, setCompanyList] = useState();
  const [CompanyName, setCompanyName] = useState();
  const [ItemFilterName, setItemFilterName] = useState("");
  const [ItemFilterValue, setItemFilterValue] = useState("");
  const [OrderEntryTransactionDefinition, setOrderEntryTransactionDefinition] =
    useState();
  const [Username, setUsername] = useState();

  if (!Username) {
    setUsername(context.usr);
  }

  const loadData = useCallback(async () => {
    var siteID = params.NewSiteID;
    setSiteID(siteID);
    await trackPromise(
      Promise.all([axios.get("/api/Syscompanies/GetSyscompany/" + siteID)])
        .then((response) => {
          if (response) {
            setCompanyList(response[0].data);
            setCompanyName(response[0].data[0].id);
          }
        })
        .catch((e) => {
          var message = apiError("SetCompany Get: ", e);
          setErrorList((prevState) => ({ ...prevState, message }));
        })
    );
  }, [params.NewSiteID]);

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [loadData]);

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name, theData);
    var pattClearErrorList = new RegExp("ClearErrorList");
    if (pattClearErrorList.test(name)) {
      setErrorList(ErrorList.filter((item) => item !== theData));
    } else {
      setter(theData);
    }
  };

  const handleFormReset = (e) => {
    e.preventDefault();
    resetForm();
  };
  const resetForm = useCallback(async () => {
    loadData();
  }, [loadData]);

  const validateForm = () => {
    setValidationErrorList([]);
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "ItemFilterName",
          data: ItemFilterName,
          schema: ItemFilterNameSchema,
        },
        {
          name: "ItemFilterValue",
          data: ItemFilterValue,
          schema: ItemFilterValueSchema,
        },
        {
          name: "OrderEntryTransactionDefinition",
          data: OrderEntryTransactionDefinition,
          schema: OrderEntryTransactionDefinitionSchema,
        },
      ];
      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            setValidationErrorList((errorList) => [...errorList, e]);
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm()
      .then(async () => {
        let outputJSON = {
          CompanyListId: CompanyName,
          ItemFilterName: ItemFilterName,
          ItemFilterValue: ItemFilterValue,
          OrderEntryTransactionDefinition: OrderEntryTransactionDefinition,
          SiteID,
        };

        // console.log(outputJSON);
        await trackPromise(
          axios
            .post("api/SyscompanySettings/" + Username, outputJSON)
            .then(async (responses) => {
              if (responses) {
                navigate("/NewSite", {
                  state: {
                    PostFeedback: ["Site has been successfully setup."],
                  },
                });
              }
            })
            .catch((e) => {
              var message = apiError("PostAPI: ", e);
              setErrorList((ErrorList) => [...ErrorList, message]);
            })
        );
      })
      .catch((e) => {});
  };

  if (CompanyList)
    return (
      <div className="NewSite">
        <h1>New Site</h1>

        <OutputMessage errorList={ErrorList} />

        <div className="detailsContent">
          <Form id="NewSiteForm" onReset={handleFormReset}>
            <FormDropdown
              id="CompanyName"
              Label="Company"
              name="CompanyName"
              placeholder={CompanyName}
              itemContent={CompanyList}
              value={handleCallback(setCompanyName)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
            />

            <FormInputBox
              type="text"
              Label="Item Filter Name"
              name="ItemFilterName"
              placeholder={ItemFilterName}
              ConvertToUppercase="true"
              value={handleCallback(setItemFilterName)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              // disabled={notEditable}
              errorList={handleCallback(setValidationErrorList)}
              schema={ItemFilterNameSchema}
            />
            <FormInputBox
              type="text"
              Label="Item Filter Value"
              name="ItemFilterValue"
              placeholder={ItemFilterValue}
              value={handleCallback(setItemFilterValue)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={ItemFilterValueSchema}
            />
            <FormInputBox
              type="text"
              Label="Order Entry Transaction Definition"
              name="OrderEntryTransactionDefinition"
              placeholder={OrderEntryTransactionDefinition}
              value={handleCallback(setOrderEntryTransactionDefinition)}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              errorList={handleCallback(setValidationErrorList)}
              schema={OrderEntryTransactionDefinitionSchema}
            />

            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={4} className="IconBtn">
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleSubmit}
                  name="Submit"
                >
                  <FaSave /> Save
                </Button>
                {ValidationErrorList.length >= 1 ? (
                  <ValidationError
                    errorListState={ValidationErrorList}
                    ClearErrorList={handleCallback(setValidationErrorList)}
                  />
                ) : null}
                <Button variant="primary" onClick={handleFormReset}>
                  <FaTimesCircle /> Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
};

export default SetCompany;
