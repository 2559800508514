import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";

const OutputMessage = (props) => {
  const [{ AddedSuccess, UpdateSuccess, PostFeedback }, setState] = useState({
    AddedSuccess: props.updateSuccess ? true : false,
    UpdateSuccess: props.updateSuccess ? true : false,
    PostFeedback: props.PostFeedback ? true : false,
  });
  const setTheState = (StateElement, value) => {
    setState((prevState) => ({ ...prevState, [StateElement]: value }));
  };

  useEffect(() => {
    if (props.AddedSuccess) {
      setTheState("AddedSuccess", true);
    }
    if (props.updateSuccess) {
      setTheState("UpdateSuccess", true);
    }
    if (props.PostFeedback) {
      setTheState("PostFeedback", true);
    }
  }, [props.AddedSuccess, props.PostFeedback, props.updateSuccess]);

  let RenderItem = (Name, Label, Item) => {
    return (
      <>
        {[Name] ? (
          <div
            className={
              Label === "Error"
                ? "detailsContent ErrorList"
                : "detailsContent Infobox"
            }
          >
            <Row>
              <Col>
                <div style={{ padding: "0.375rem 2rem", textAlign: "center" }}>
                  {Item}
                </div>
              </Col>
              <div
                className="CloseButton close cross"
                onClick={() => {
                  if (Label === "Error") {
                    props.ClearErrorList("ClearErrorList", Item);
                  }
                  setTheState([Name], false);
                }}
              >
                <FaTimesCircle />
              </div>
            </Row>
          </div>
        ) : null}
      </>
    );
  };

  let errorRemovedDuplicates = [...new Set(props.errorList)];
  let NotFetchStatus = true;

  return (
    <>
      {errorRemovedDuplicates && NotFetchStatus
        ? errorRemovedDuplicates.constructor === Array
          ? errorRemovedDuplicates.map((Element, i) => {
              if (Element !== null) {
                return <div key={i}>{RenderItem(i, "Error", Element)}</div>;
              } else return null;
            })
          : RenderItem("ErrorList", "Error", errorRemovedDuplicates)
        : null}

      {props.PostFeedback && PostFeedback
        ? props.PostFeedback.map((Element, i) => {
            return (
              <div key={i}>
                {Element !== "" ? (
                  <>
                    {RenderItem("PostFeedback", "APIFeedback:", <>{Element}</>)}
                  </>
                ) : null}
              </div>
            );
          })
        : null}

      {props.errorList.length <= 0 ? (
        <>
          {props.AddedSuccess && AddedSuccess
            ? RenderItem("AddedSuccess", "", "Record(s) Successfully Added")
            : null}

          {props.updateSuccess && UpdateSuccess
            ? RenderItem("UpdateSuccess", "", "Record(s) Successfully Updated")
            : null}
        </>
      ) : null}
    </>
  );
};

export default OutputMessage;
