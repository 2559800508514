import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import FormTablePopup from "./FormTablePopup";
import ListSort from "../../libs/ListSort";

let blankState = {
  notEditable: true,
  editVisible: true,
  NoData: false,
  reloadPage: false,

  errorList: [],
};

const FormTable = (props) => {
  const [state, setState] = useState(blankState);

  let handleCallback = (name, theData) => {
    // console.log(name, theData);

    var pattErrorList = new RegExp("errorList"),
      pattSave = new RegExp("Save"),
      pattRemovalSuccess = new RegExp("RemovalSuccess"),
      pattUpdateSuccess = new RegExp("updateSuccess"),
      pattAddedSuccess = new RegExp("AddedSuccess");

    if (pattErrorList.test(name)) {
      state.errorList = [...state.errorList, theData];
      props.errorList(name, theData);
    } else if (pattSave.test(name)) {
      let newItemContent = props.itemContent;

      theData.forEach((i) => {
        var index = newItemContent.findIndex((obj) => obj.id === i.id);
        newItemContent[index].isAllowable = i.toggleChecked;
        newItemContent[index].isAllowableForReceipts = i.isAllowableForReceipts;
        newItemContent[index].isAllowableForPayments = i.isAllowableForPayments;
      });

      props.value(props.name, ListSort(newItemContent));
    } else {
      state[name] = theData;
    }

    if (
      pattRemovalSuccess.test(name) ||
      pattAddedSuccess.test(name) ||
      pattUpdateSuccess.test(name)
    ) {
      setState({ [name]: theData });
    }
  };

  return !props.hidden ? (
    <>
      <Form.Group className={"FormGroup"} as={Row} controlId={props.name}>
        <Form.Label column sm={props.LabelColumnAmount}>
          {props.Label}
        </Form.Label>
        <Col sm={8}>
          <Row>
            {props.name === "JournalTypeLists" ? (
              <Col sm={12}>
                {props.itemContent.map((element, i) =>
                  element.isAllowableForPayments ||
                  element.isAllowableForReceipts ? (
                    <Form.Control
                      key={i}
                      plaintext
                      readOnly
                      value={
                        element.isAllowableForPayments
                          ? `${element.content} - Allowable For Payments`
                          : `${element.content} - Allowable For Receipts`
                      }
                    />
                  ) : null
                )}
              </Col>
            ) : (
              <Col sm={12}>
                {props.itemContent.map((element, i) =>
                  element.isAllowable ? (
                    <Form.Control
                      key={i}
                      plaintext
                      readOnly
                      value={element.content}
                    />
                  ) : null
                )}
              </Col>
            )}

            {!props.disabled ? (
              <Col sm={12}>
                <div className="form-control-plaintext">
                  <FormTablePopup
                    name={props.name}
                    rowData={props.itemContent}
                    value={handleCallback}
                    errorList={handleCallback}
                    updateSuccess={handleCallback}
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Form.Group>
    </>
  ) : null;
};

export default FormTable;
