import React from "react";
import { Container, Row } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
import CookieConsent from "react-cookie-consent";
// import { AppContext } from "../libs/contextLib";
export default function Footer() {
  // const context = useContext(AppContext);
  return (
    <footer>
      <Container>
        {/* {context.SettingsList.IsSuperAdmin ? (
          <>
            <Row>
              <Col sm={10}>
                <LinkContainer to="/NewSite">
                  <Nav.Link>New Site</Nav.Link>
                </LinkContainer>
              </Col>
            </Row>
          </>
        ) : null} */}
        <Row>
          <CookieConsent
            disableStyles={true}
            location="bottom"
            buttonClasses="btn btn-primary"
            containerClasses="cookieConsent alert alert-warning col-lg-12"
            contentClasses="text-capitalize"
            buttonText="Accept"
            cookieName="WebReceipts"
            expires={150}
          >
            The Web Receipts App uses cookies to enhance the user experience.{" "}
          </CookieConsent>
        </Row>
      </Container>
    </footer>
  );
}
