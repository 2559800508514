import React, { useContext } from "react";
import { AppContext } from "../../libs/contextLib";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FaSync } from "react-icons/fa";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import apiError from "../../libs/apiError";

export const SyncEntities = (props) => {
  const context = useContext(AppContext);
  let HandleSync = async (e) => {
    e.preventDefault();
    let SiteID = sessionStorage.getItem("siteID");
    await trackPromise(
      Promise.all([
        axios
          .get("/api/SyscompanySyncs/GetSyscompanySync/" + SiteID)
          .catch((e) => {
            var message = apiError("SyncCompanyList Error: ", e);
            props.errorList("errorList", message);
          }),
      ])
        .then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            props.PostFeedback("PostFeedback", [responses[0].data]);
          }
        })
        .catch((e) => {
          var message = apiError("SyncCompanyList Error:", e);
          props.errorList("errorList", message);
        })
    );
  };

  if (context.SettingsList.IsAdmin) {
    let SyncLabel = "Fetch";

    return (
      <div className="right TooltipActionButton">
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="ReloadInfoTooltip">{SyncLabel} Intacct Data</Tooltip>
          }
        >
          <Button variant="primary" onClick={HandleSync}>
            <span>
              <FaSync />
            </span>
            <span>{SyncLabel}</span>
          </Button>
        </OverlayTrigger>
      </div>
    );
  } else return null;
};
