import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
registerPlugin(FilePondPluginFileEncode);

const AddAttachment = (props) => {
  const [errorList, seterrorList] = useState();
  const [name] = useState(props.name);
  const [AttachmentList, setAttachmentList] = useState();
  useEffect(() => {
    if (props.AttachmentList.length === 0) {
      setAttachmentList();
    }
  }, [props.AttachmentList.length]);
  return (
    <>
      <Form.Group className={"FormGroup"} as={Row} controlId={name}>
        <Form.Label column sm={props.LabelColumnAmount}>
          {props.Label}
        </Form.Label>
        <Col sm={props.InputColumnAmount}>
          <FilePond
            files={AttachmentList}
            className={"form-control"}
            onupdatefiles={(fileItems) => {
              let theList = fileItems.map((fileItem) => {
                var FileContent = {
                  Filename: fileItem.file.name,
                  Base64String: fileItem.getFileEncodeBase64String(),
                };

                return FileContent;
              });
              setAttachmentList(fileItems);
              props.value(props.name, theList);
            }}
            onerror={(error) => {
              seterrorList(error);
            }}
            allowFileEncode={true}
            allowMultiple={true}
            labelIdle='Drop file here or <span class="filepond--label-action">Click to upload</span>.'
          />
        </Col>
        <Col>
          {errorList && errorList[name] ? (
            <div className="invalid-tooltip typeError">{errorList[name]}</div>
          ) : null}
          {!errorList && props.errorList[name] ? (
            <div className="invalid-tooltip typeError">
              {props.errorList[name]}
            </div>
          ) : null}
        </Col>
      </Form.Group>
    </>
  );
};

export default AddAttachment;
