import React, { useContext } from "react";
import FormLabelbox from "../Form/FormLabelbox";
import { ReceiptContext } from "../../libs/ReceiptContextLib";

const GrandTotalReceiptLine = (props) => {
  const Receipt = useContext(ReceiptContext);
  const ColumnAmount = Receipt.ReceiptHeaderItems.TaxType === "None" ? 6 : 9;
  return (
    <tr id={"RowElementGrandTotalLine"} role="row" className={"RowElement"}>
      {[...Array(ColumnAmount)].map((x, i) => (
        <td key={i} role="cell"></td>
      ))}
      <td role="cell" className="right">
        <FormLabelbox
          type="text"
          name="GrandTotalDebit"
          placeholder={Receipt.GrandTotalDebit}
        />
      </td>
      <td role="cell" className="right">
        <FormLabelbox
          type="text"
          name="GrandTotalCredit"
          placeholder={Receipt.GrandTotalCredit}
        />
      </td>
      <td role="cell" className="right"></td>
    </tr>
  );
};

export default GrandTotalReceiptLine;
