import React, { useEffect, useState, useMemo, useCallback } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useTable, usePagination } from "react-table";
import { FaTimesCircle, FaSave } from "react-icons/fa";
import { Table } from "react-bootstrap";
import { PaginationComponents } from "../PaginationComponents";
import { Row, Col, Button } from "react-bootstrap";
import { SetObserver } from "../../libs/Observer";
import OverlayFormatter from "../../libs/OverlayFormatter";
import FormCheck from "../Form/FormCheck";
import {
  LabelColumnAmount,
  InputColumnAmount,
  ValidationError,
} from "../../libs/Variables";

const FormTablePopup = (props) => {
  const [validationErrorList, setvalidationErrorList] = useState([]);
  const [ValuesToUpdate, setValuesToUpdate] = useState([]);
  const [RowData, setRowData] = useState(props.rowData);

  const handleCallback = useCallback((name, theData) => {
    // console.log(name);
    // console.log(theData);
    if (name === "isAllowable") {
      setValuesToUpdate((ValuesToUpdate) => {
        const IsRefPresent = ValuesToUpdate.some((Element) => {
          if (Element.id === theData.id) return true;
          else return false;
        });

        if (IsRefPresent) {
          const UpdatedValue = ValuesToUpdate.map((Element) => {
            if (Element.id === theData.id) {
              return {
                ...Element,
                id: theData.id,
                toggleChecked: theData.toggleChecked,
              };
            }
            return Element;
          });

          return UpdatedValue;
        } else {
          return [...ValuesToUpdate, theData];
        }
      });
    }
  }, []);

  let columns = useMemo(() => {
    if (props.name === "JournalTypeLists") {
      return [
        {
          Header: "ID",
          accessor: "id",
        },
        {
          Header: "Name",
          accessor: "content",
          Cell: (CellContent) => {
            return OverlayFormatter(CellContent.value);
          },
        },
        {
          Header: "Is Allowable For Payments?",
          accessor: "isAllowableForPayments",
          Cell: (cell) => {
            return (
              <FormCheck
                type="switch"
                name="isAllowableForPayments"
                CheckAlone
                id={cell.row.original.id}
                initialState={RowData[cell.row.id].isAllowableForPayments}
                value={handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
              />
            );
          },
        },
        {
          Header: "Is Allowable For Receipts?",
          accessor: "isAllowableForReceipts",
          Cell: (cell) => {
            return (
              <FormCheck
                type="switch"
                name="isAllowableForReceipts"
                CheckAlone
                id={cell.row.original.id}
                initialState={RowData[cell.row.id].isAllowableForReceipts}
                value={handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
              />
            );
          },
        },
      ];
    } else {
      return [
        {
          Header: "ID",
          accessor: "id",
        },
        {
          Header: "Name",
          accessor: "content",
          Cell: (CellContent) => {
            return OverlayFormatter(CellContent.value);
          },
        },
        {
          Header: "Is Allowable?",
          accessor: "isAllowable",
          Cell: (cell) => {
            return (
              <FormCheck
                type="switch"
                name="isAllowable"
                CheckAlone
                id={cell.row.original.id}
                initialState={cell.value}
                value={handleCallback}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
              />
            );
          },
        },
      ];
    }
  }, [props.name, handleCallback, RowData]);

  useEffect(() => {
    SetObserver();
  }, []);

  return (
    <Popup
      className="FormTablePopup"
      trigger={<Button variant="primary">Edit List</Button>}
      modal
      nested
      closeOnDocumentClick={false}
    >
      {(Close) => (
        <div className="Modal">
          <button
            className="close"
            onClick={() => {
              setRowData(props.rowData);
              Close();
            }}
          >
            <FaTimesCircle />
          </button>
          <div className="content">
            <TheTable
              columns={columns}
              data={RowData}
              getCellProps={(cell) => ({
                onClick: () => {
                  if (props.name === "JournalTypeLists") {
                    let RowID = cell.row.allCells[0].value;
                    let ColumnName = cell.column.id;
                    let AllowableForPayments = cell.row.allCells[2].value,
                      AllowableForReceipts = cell.row.allCells[3].value;
                    let SetBool;
                    if (ColumnName === "isAllowableForPayments")
                      SetBool = {
                        AllowableForPayments: true,
                        AllowableForReceipts: false,
                      };
                    else if (ColumnName === "isAllowableForReceipts") {
                      SetBool = {
                        AllowableForPayments: false,
                        AllowableForReceipts: true,
                      };
                    } else {
                      SetBool = {
                        AllowableForPayments: false,
                        AllowableForReceipts: false,
                      };
                    }

                    if (AllowableForPayments) {
                      SetBool.AllowableForPayments = false;
                    } else if (AllowableForReceipts) {
                      SetBool.AllowableForReceipts = false;
                    }

                    setRowData((setRow) => {
                      const RowUpdate = setRow.map((Element) => {
                        if (Element.id === RowID) {
                          return {
                            ...Element,
                            isAllowableForPayments:
                              SetBool.AllowableForPayments,
                            isAllowableForReceipts:
                              SetBool.AllowableForReceipts,
                          };
                        }
                        return Element;
                      });
                      return RowUpdate;
                    });
                    setValuesToUpdate((ValuesToUpdate) => {
                      const IsRefPresent = ValuesToUpdate.some((Element) => {
                        if (Element.id === RowID) return true;
                        else return false;
                      });

                      if (IsRefPresent) {
                        const UpdatedValue = ValuesToUpdate.map((Element) => {
                          if (Element.id === RowID) {
                            return {
                              ...Element,
                              RowName: cell.row.allCells[1].value,
                              id: RowID,
                              isAllowableForPayments:
                                SetBool.AllowableForPayments,
                              isAllowableForReceipts:
                                SetBool.AllowableForReceipts,
                            };
                          }
                          return Element;
                        });
                        return UpdatedValue;
                      } else {
                        return [
                          ...ValuesToUpdate,
                          {
                            RowName: cell.row.allCells[1].value,
                            id: RowID,
                            isAllowableForPayments:
                              SetBool.AllowableForPayments,
                            isAllowableForReceipts:
                              SetBool.AllowableForReceipts,
                          },
                        ];
                      }
                    });
                  }
                },
              })}
            />
            <Row>
              <Col sm={LabelColumnAmount}></Col>
              <Col sm={4} className="IconBtn">
                <Button
                  variant="primary"
                  onClick={async (e) => {
                    if (props.name === "JournalTypeLists") {
                      ValuesToUpdate.forEach((element) => {
                        if (
                          element.isAllowableForPayments &&
                          element.isAllowableForReceipts
                        ) {
                          setvalidationErrorList((validationErrorList) => [
                            ...validationErrorList,
                            `Both IsAllowed Boxes cant't be selected for ${element.RowName}.`,
                          ]);
                        }
                      });

                      if (validationErrorList.length <= 0) {
                        props.value("Save", ValuesToUpdate);
                        Close();
                      }
                    } else {
                      props.value("Save", ValuesToUpdate);
                      Close();
                    }
                  }}
                  type="submit"
                >
                  <FaSave /> Save
                </Button>
                {validationErrorList.length >= 1
                  ? ValidationError(JSON.stringify(validationErrorList))
                  : null}

                <Button
                  variant="primary"
                  onClick={() => {
                    setRowData(props.rowData);
                    Close();
                  }}
                >
                  <FaTimesCircle /> Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Popup>
  );
};

const defaultPropGetter = () => ({});
const TheTable = ({
  columns,
  data,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: ["id"],
        sortBy: [
          {
            id: "content",
          },
        ],
      },
      autoResetPage: false,
    },
    // useSortBy,
    usePagination
  );

  return (
    <div className="react-table">
      <Table hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {
                    ...column.getHeaderProps(/*column.getSortByToggleProps()*/)
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {PaginationComponents(
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageIndex,
        pageSize
      )}
    </div>
  );
};

export default FormTablePopup;
