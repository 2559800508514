import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { AppContext } from "../libs/contextLib";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FaSignInAlt } from "react-icons/fa";
import { AppConsumer } from "../libs/contextLib";
import * as yup from "yup";
import moment from "moment";
import FormInputBox from "../components/Form/FormInputbox";
import axios from "axios";
import apiError from "../libs/apiError";
import withRouter from "../libs/withRouter";
import { trackPromise } from "react-promise-tracker";
import SetSettingsList from "../components/SetSettingsList";
import OutputMessage from "../components/OutputMessage";

import {
  passRegex,
  PasswordRequirements,
  emailRegex,
  emailRegexMessage,
  inputRegex,
  inputRegexMessage,
  ValidationError,
} from "../libs/Variables";

const schemaPassword = yup
    .string()
    .required("Password is required")
    .min(12, "Password does not meet requirements")
    .max(160, "Password does not meet requirements")
    .matches(passRegex, "Password does not meet requirements"),
  schemaUsername = yup
    .string()
    .required("Username is required")
    .matches(emailRegex, emailRegexMessage)
    .max(100, "Username must be less than 100 characters"),
  schemaSiteName = yup
    .string()
    .required("Site Name is required")
    .nullable()
    .matches(inputRegex, "Site Name" + inputRegexMessage)
    .max(100, "Site Name must be less than 100 characters");

const schemaLogin = yup.object().shape({
    password: schemaPassword,
    username: schemaUsername,
    siteName: schemaSiteName,
  }),
  schemaPasswordReset = yup.object({
    newPassword: schemaPassword,
  });

const LabelColumnAmount = 2,
  InputColumnAmount = 8;

class Login extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    let setSiteName = sessionStorage.getItem("siteName");
    this.state = {
      resetUser: null,
      newPassword: "",
      username: "",
      password: "",
      siteName: setSiteName,
      errorList: [],
      authErrorList: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);

    if (this.props.location.state && this.props.location.state !== undefined) {
      this.state.PasswordResetSuccess =
        this.props.location.state.PasswordResetSuccess;
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleCallback = (name, theData) => {
    this.setState((state) => {
      var pattErrorList = new RegExp("errorList"),
        pattClearValidationErrorList = new RegExp("ClearValidationErrorList"),
        pattClearErrorList = new RegExp("ClearErrorList");
      if (pattErrorList.test(name)) {
        state.errorList = [...state.errorList, theData];
      } else if (pattClearValidationErrorList.test(name)) {
        state.authErrorList = [];
      } else if (pattClearErrorList.test(name)) {
        state.errorList = state.errorList.filter((item) => item !== theData);
      } else {
        state[name] = theData;
      }
      return state;
    });
  };

  async handleLogin() {
    await trackPromise(
      Auth.signIn(this.state.username, this.state.password)
        .then((setResetUser) => {
          // console.log(setResetUser);
          if (setResetUser.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.setState({
              resetUser: setResetUser,
              validated: false,
              errorList: [],
              newPassword: "",
              username: "",
              password: "",
            });
          } else {
            this.GetUserSettings();
            let now = moment().format("DD-MM-YYYY HH:mm:ss");
            sessionStorage.setItem("LoggedInTime", now);
            this.context.setUsr(setResetUser.attributes.email);
          }
        })
        .catch((e) => {
          var message = apiError("API Sign In: ", e);
          if (!this.state.authErrorList.includes(message)) {
            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, message],
            }));
          }
        })
    );
  }

  async GetUserSettings() {
    await trackPromise(
      Promise.all([
        axios.get(
          "/api/UserSites/GetUserSettings/" +
            this.state.siteID +
            "/" +
            this.state.username
        ),
        axios.get(
          "/api/SyscompanySyncs/GetSyscompanySync/" + this.state.siteID
        ),
      ])
        .then((responses) => {
          if (responses[0] && responses[0] !== undefined) {
            var placeholderArr =
              responses[0].data[responses[0].data.length - 1];
            if (placeholderArr.isActive) {
              let SettingsList = SetSettingsList(placeholderArr);

              this.setState({ DataLoaded: true }, () => {
                this.context.setIsAuthenticated(true);
                this.context.setSettingsList(SettingsList);
                this.props.navigate("/");
              });
            } else {
              throw new Error("Issue with Login - User might be Inactive.");
            }
          }
        })
        .catch((e) => {
          var message = apiError("API User Settings Get: ", e);
          if (!this.state.authErrorList.includes(message)) {
            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, message],
            }));
          }
        })
    );
  }

  async handleReset(e) {
    e.preventDefault();
    this.setState({ errorList: [], authErrorList: [] }, () => {
      let validateString = {
        newPassword: this.state.newPassword,
      };
      this.validateForm(schemaPasswordReset, validateString).then(async () => {
        if (this.state.validated) {
          await trackPromise(
            Auth.completeNewPassword(
              this.state.resetUser,
              this.state.newPassword
            )
              .then((setResetUser) => {
                let newUsername =
                  setResetUser.challengeParam.userAttributes.email;
                this.setState({ username: newUsername }, () => {
                  this.context.setUsr(newUsername);
                  this.GetUserSettings();
                });
              })
              .catch((e) => {
                var message = apiError("API Complete new password: ", e);
                if (!this.state.authErrorList.includes(message)) {
                  this.setState((previousState) => ({
                    authErrorList: [...previousState.authErrorList, message],
                  }));
                }
              })
          );
        }
      });
    });
  }

  async validateForm(schema, validateString) {
    var validateResult = await trackPromise(
      schema
        .validate(validateString, { abortEarly: false })
        .then(() => {
          return true;
        })
        .catch((e) => {
          var message = {};

          e.inner.forEach((e) => {
            console.log(e.message);

            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, e.message],
            }));
            // message[e.path] = e.message;
          });
          return message;
        })
    );

    if (validateResult === true) {
      this.setState({ validated: true, errorList: [] }, () => {
        return true;
      });
    } else {
      this.setState({ validated: false });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ errorList: [], authErrorList: [] }, () => {
      let validateString = {
        username: this.state.username,
        password: this.state.password,
        siteName: this.state.siteName,
      };
      this.validateForm(schemaLogin, validateString).then(() => {
        if (this.state.validated) {
          this.getSiteNameSubmit();
        }
      });
    });
  }

  async getSiteNameSubmit() {
    await trackPromise(
      Promise.all([
        axios.get("/api/sitesettings/" + this.state.siteName).catch((e) => {
          var message = apiError("API SiteSettings: ", e);
          if (!this.state.authErrorList.includes(message)) {
            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, message],
            }));
          }
        }),
      ])
        .then(async (responses) => {
          if (!responses[0]) {
            throw new Error("Issue getting Site Settings.");
          }
          let SageInfo = responses[0].data,
            siteID = SageInfo[0].siteID,
            SiteSettingsID = SageInfo[0].siteSettingsID,
            CompanyID = SageInfo[0].companyID,
            IPWhitelistSetting = SageInfo[0].useIPWhitelist;
          this.setState({ siteID: siteID });
          if (siteID) {
            sessionStorage.setItem("siteID", siteID);
            sessionStorage.setItem("SiteSettingsID", SiteSettingsID);
            sessionStorage.setItem("CompanyID", CompanyID);
            this.context.IPWhitelistSetting = IPWhitelistSetting;
            sessionStorage.setItem("siteName", this.state.siteName);
            this.handleLogin();
          } else {
            throw new Error("No Active Site Found.");
          }
        })
        .catch((e) => {
          sessionStorage.setItem("siteID", "");
          sessionStorage.setItem("SiteSettingsID", "");
          // sessionStorage.setItem("productName", "Sage Intacct");
          sessionStorage.setItem("siteName", "");
          var message = apiError("API Get: ", e);
          if (!this.state.authErrorList.includes(message)) {
            this.setState((previousState) => ({
              authErrorList: [...previousState.authErrorList, message],
            }));
          }
        })
    );
  }

  renderPasswordReset() {
    return (
      <Form onSubmit={this.handleReset}>
        <Row>
          <Col sm={LabelColumnAmount}></Col>
          <Col sm={InputColumnAmount}>
            <p>You are required to change your password.</p>
            {PasswordRequirements()}
          </Col>
        </Row>

        <FormInputBox
          type="password"
          Label="New Password"
          name="newPassword"
          placeholder=""
          value={this.handleCallback}
          LabelColumnAmount={3}
          InputColumnAmount={6}
          errorList={this.state.authErrorList}
          schema={schemaPassword}
          autoComplete="new-password"
        />

        <Row>
          <Col sm={LabelColumnAmount}></Col>
          <Col sm={5}>
            <Button variant="primary" type="submit">
              <FaSignInAlt /> Go
            </Button>
            {this.state.authErrorList.length >= 1 ? (
              <ValidationError
                errorListState={this.state.authErrorList}
                ClearErrorList={this.handleCallback}
              />
            ) : null}
          </Col>
        </Row>
      </Form>
    );
  }

  renderLogin() {
    return (
      <>
        {this.state.PasswordResetSuccess ? (
          <>
            <h4>Password successfully reset</h4>
            <p>Please login as normal using your new password</p>
          </>
        ) : null}
        <Form onSubmit={this.handleSubmit}>
          <FormInputBox
            type="text"
            Label="Site Name"
            name="siteName"
            placeholder={this.state.siteName}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            errorList={this.state.authErrorList}
            schema={schemaSiteName}
          />

          <FormInputBox
            type="text"
            Label="Username"
            name="username"
            placeholder={this.state.username}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            errorList={this.state.authErrorList}
            schema={schemaUsername}
            autocomplete="username"
          />

          <FormInputBox
            type="password"
            Label="Password"
            name="password"
            placeholder={this.state.password}
            value={this.handleCallback}
            LabelColumnAmount={LabelColumnAmount}
            InputColumnAmount={InputColumnAmount}
            errorList={this.state.authErrorList}
            schema={schemaPassword}
            autocomplete="current-password"
          />

          <Row>
            <Col sm={LabelColumnAmount}></Col>
            <Col sm={5} className="IconBtn">
              <Button variant="primary" type="submit">
                <FaSignInAlt /> Go
              </Button>
              {this.state.authErrorList.length >= 1 ? (
                <ValidationError
                  errorListState={this.state.authErrorList}
                  ClearErrorList={this.handleCallback}
                />
              ) : null}
            </Col>
            <Col sm={5}>
              <Link to="/ResetPassword">
                <Button variant="primary">Forgot Password?</Button>
              </Link>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  render() {
    let params = this.props.location.state;
    return (
      <AppConsumer>
        {() => (
          <>
            <h1>Login</h1>
            <div className="Login">
              {params ? (
                <OutputMessage
                  errorList={this.state.errorList}
                  ClearErrorList={this.handleCallback}
                  updateSuccess={this.state.updateSuccess}
                  AddedSuccess={this.state.AddedSuccess}
                  PostFeedback={params.PostFeedback}
                />
              ) : null}
              {this.state.resetUser === null
                ? this.renderLogin()
                : this.renderPasswordReset()}
            </div>
          </>
        )}
      </AppConsumer>
    );
  }
}

Login.contextType = AppContext;

export default withRouter(Login);
