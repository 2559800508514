import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";

export default function IsLoading() {
  return (
    <div className="LoaderContainer">
      <Loader promiseTracker={usePromiseTracker} />
    </div>
  );
}
