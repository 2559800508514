import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

export default function CheckIfValueReturned(value) {
  if (value) {
    return (
      <div className="tick">
        <FaCheckCircle />
      </div>
    );
  } else {
    return (
      <div className="cross">
        <FaTimesCircle />
      </div>
    );
  }
}
