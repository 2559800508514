import React, { useState, useContext } from "react";
import * as yup from "yup";
import FormInputBox from "../Form/FormInputbox";
import FormLabelbox from "../Form/FormLabelbox";
import FormDropdown from "../Form/FormDropdown";
import { ReceiptContext } from "../../libs/ReceiptContextLib";
import DropdownOverlayFormatter from "../../libs/DropdownOverlayFormatter";
import { inputRegex, inputRegexMessage } from "../../libs/Variables";
import RemoveReceiptLine from "./RemoveReceiptLine";

const FindIndex = (list, element, elementToReturn) => {
  var index = list.findIndex((obj) => obj.id === parseInt(element));
  if (index === -1) index = 0;
  if (!elementToReturn) {
    return list[index].content;
  } else {
    return list[index][elementToReturn];
  }
};

const NewReceiptLine = (props) => {
  const Receipt = useContext(ReceiptContext),
    ID = props.ID,
    LineContent = Receipt.LineContent,
    i = Receipt.RowContentList.findIndex((obj) => obj.ID === parseInt(ID)),
    RowContentList = Receipt.RowContentList[i];

  const [/*ErrorList,*/ setErrorList] = useState([]);
  const [Memo, setMemo] = useState(RowContentList.Memo);
  const [Department, setDepartment] = useState(RowContentList.Department);
  const [ItemCode, setItemCode] = useState(RowContentList.ItemCode);
  const [Class, setClass] = useState(RowContentList.Class);
  const [TaxDetails, setTaxDetails] = useState(RowContentList.TaxDetails);
  const [Debit, setDebit] = useState(RowContentList.Debit);
  const [Credit, setCredit] = useState(RowContentList.Credit);
  const [Rate, setRate] = useState(RowContentList.Rate);
  const [TaxAmount, setTaxAmount] = useState(RowContentList.TaxAmount);
  const [TotalDebit, setTotalDebit] = useState(RowContentList.TotalDebit);
  const [TotalCredit, setTotalCredit] = useState(RowContentList.TotalCredit);

  const [DebitSelected, setDebitSelected] = useState(
    RowContentList.DebitSelected
  );
  const [CreditSelected, setCreditSelected] = useState(
    RowContentList.CreditSelected
  );

  const MemoSchema = yup
      .string()
      .nullable()
      .matches(inputRegex, inputRegexMessage)
      .max(1000, "Must be less than 1000 characters"),
    MoneySchema = yup
      .string()
      .required("Required")
      .matches(
        /^(\+)?([0-9]+(\.[0-9]{1,2})?)$/,
        "Must be a positive decimal correct to 2 places."
      );
  // TaxAmountSchema = yup.number("Must be a number").required("Required");
  // .matches(
  //   /^(\+)?([0-9]+(\.[0-9]{1,2})?)$/,
  //      "Must be a positive decimal correct to 2 places."
  // )

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name, theData);

    const UpdateContentList = (elementName, Data) => {
      Receipt.setRowContentList((RowContentList) => {
        var index = RowContentList.findIndex((obj) => obj.ID === ID);
        RowContentList[index][elementName] = Data;
        return [...RowContentList];
      });
    };

    const UpdateTotals = (elementName, Data, DebitCreditVal) => {
      if (isNaN(Data) || !Data) {
        Data = 0;
      }
      if (isNaN(DebitCreditVal) || !DebitCreditVal) {
        DebitCreditVal = 0;
      }
      var OldValue, updateValue, Current, NewTotal, TotalIncTax;

      if (elementName === "Debit" || elementName === "Credit") {
        var newTaxAmount = parseFloat(
          (parseFloat(Data) * parseFloat(Rate)) / 100
        );
        setTaxAmount(newTaxAmount.toFixed(2));
        UpdateContentList("TaxAmount", newTaxAmount.toFixed(2));
        TotalIncTax = parseFloat(Data) + newTaxAmount;
        switch (elementName) {
          case "Debit":
            OldValue = TotalDebit;
            updateValue = TotalIncTax.toFixed(2);
            setTotalDebit(updateValue);
            break;
          case "Credit":
            updateValue = TotalIncTax.toFixed(2);
            OldValue = TotalCredit;
            setTotalCredit(updateValue);
            break;
          default:
            break;
        }
        OldValue = parseFloat(OldValue);
        UpdateContentList(`Total${elementName}`, updateValue);

        if (OldValue !== updateValue) {
          Current = parseFloat(Receipt[`GrandTotal${elementName}`]);
          NewTotal = Current - OldValue;
          NewTotal = parseFloat(
            parseFloat(NewTotal) + parseFloat(updateValue)
          ).toFixed(2);
          Receipt[`setGrandTotal${elementName}`](NewTotal);
        }
      }

      if (elementName === "TaxAmount" || elementName === "TaxDetails") {
        newTaxAmount =
          elementName === "TaxAmount"
            ? parseFloat(Data)
            : (parseFloat(DebitCreditVal) * parseFloat(Data)) / 100;

        TotalIncTax = parseFloat(DebitCreditVal) + newTaxAmount;
        setTaxAmount(newTaxAmount.toFixed(2));
        UpdateContentList("TaxAmount", newTaxAmount.toFixed(2));
        updateValue = TotalIncTax.toFixed(2);
        var GrandTotalToUpdate;
        if (DebitSelected) {
          OldValue = TotalDebit;
          setTotalDebit(updateValue);
          UpdateContentList(`TotalDebit`, updateValue);
          GrandTotalToUpdate = "Debit";
        }

        if (CreditSelected) {
          OldValue = TotalCredit;
          setTotalCredit(updateValue);
          UpdateContentList(`TotalCredit`, updateValue);
          GrandTotalToUpdate = "Credit";
        }

        OldValue = parseFloat(OldValue);

        if (OldValue !== updateValue) {
          Current = parseFloat(Receipt[`GrandTotal${GrandTotalToUpdate}`]);
          NewTotal = Current - OldValue;
          NewTotal = parseFloat(
            parseFloat(NewTotal) + parseFloat(updateValue)
          ).toFixed(2);
          Receipt[`setGrandTotal${GrandTotalToUpdate}`](NewTotal);
        }
      }
    };

    var pattDebit = new RegExp("Debit"),
      pattCredit = new RegExp("Credit"),
      pattTaxDetails = new RegExp("TaxDetails"),
      pattTaxAmount = new RegExp("TaxAmount");
    if (pattDebit.test(name)) {
      setDebitSelected(theData !== "0.00" ? true : false);
      setDebit(theData);
      setCredit(parseFloat(0).toFixed(2));
      UpdateTotals("Debit", theData);
    } else if (pattCredit.test(name)) {
      setCreditSelected(theData !== "0.00" ? true : false);
      setCredit(theData);
      setDebit(parseFloat(0).toFixed(2));
      UpdateTotals("Credit", theData);
    } else if (pattTaxDetails.test(name)) {
      var newTaxAmount = FindIndex(LineContent.taxDetailList, theData, "value");
      setTaxDetails(theData);
      setRate(newTaxAmount.toFixed(2));
      if (DebitSelected) {
        UpdateTotals("TaxDetails", newTaxAmount, Debit);
      }
      if (CreditSelected) {
        UpdateTotals("TaxDetails", newTaxAmount, Credit);
      }
    } else if (pattTaxAmount.test(name)) {
      setTaxAmount(theData);
      if (DebitSelected) {
        UpdateTotals("TaxAmount", theData, Debit);
      }
      if (CreditSelected) {
        UpdateTotals("TaxAmount", theData, Credit);
      }
    } else {
      setter(theData);
    }
    UpdateContentList(name, theData);
  };
  const OverlayPlacement = "top";
  return (
    <tr id={"RowElement" + ID} role="row" className={"RowElement"}>
      <td role="cell">
        {DropdownOverlayFormatter(
          <FormDropdown
            id="ItemCode"
            name="ItemCode"
            placeholder={ItemCode}
            itemContent={LineContent.itemList}
            disabled={ID === 1 ? true : false}
            value={handleCallback(setItemCode)}
            //errorList={handleCallback(setErrorList)}
          />,
          FindIndex(LineContent.itemList, ItemCode),
          OverlayPlacement
        )}
      </td>
      <td role="cell">
        {DropdownOverlayFormatter(
          <FormDropdown
            id="Class"
            name="Class"
            placeholder={Class}
            itemContent={LineContent.classList}
            disabled={ID === 1 ? true : false}
            value={handleCallback(setClass)}
            //errorList={handleCallback(setErrorList)}
          />,
          FindIndex(LineContent.classList, Class),
          OverlayPlacement
        )}
      </td>
      <td role="cell">
        {DropdownOverlayFormatter(
          <FormDropdown
            id="Department"
            name="Department"
            placeholder={Department}
            itemContent={LineContent.departmentList}
            disabled={ID === 1 ? true : false}
            value={handleCallback(setDepartment)}
            //errorList={handleCallback(setErrorList)}
          />,
          FindIndex(LineContent.departmentList, Department),
          OverlayPlacement
        )}
      </td>
      <td role="cell">
        <FormInputBox
          type="text"
          name="Memo"
          placeholder={Memo}
          value={handleCallback(setMemo)}
          //   disabled={NotEditable}
          errorList={handleCallback(setErrorList)}
          schema={MemoSchema}
        />
      </td>
      <td role="cell" className="right">
        {!CreditSelected ? (
          <FormInputBox
            type="text"
            IsCurrency={true}
            name="Debit"
            placeholder={Debit}
            value={handleCallback(setDebit)}
            //   disabled={NotEditable}
            errorList={handleCallback(setErrorList)}
            schema={MoneySchema}
          />
        ) : null}
      </td>
      <td role="cell" className="right">
        {!DebitSelected ? (
          <FormInputBox
            type="text"
            IsCurrency={true}
            name="Credit"
            placeholder={Credit}
            value={handleCallback(setCredit)}
            //   disabled={NotEditable}
            errorList={handleCallback(setErrorList)}
            schema={MoneySchema}
          />
        ) : null}
      </td>
      {Receipt.ReceiptHeaderItems.TaxType === "None" ? null : (
        <>
          <td role="cell">
            {DropdownOverlayFormatter(
              <FormDropdown
                id="TaxDetails"
                name="TaxDetails"
                placeholder={TaxDetails}
                itemContent={LineContent.taxDetailList}
                disabled={ID === 1 ? true : false}
                value={handleCallback(setTaxDetails)}
                //errorList={handleCallback(setErrorList)}
              />,
              FindIndex(LineContent.taxDetailList, TaxDetails),
              OverlayPlacement
            )}
          </td>
          <td role="cell" className="right">
            <FormLabelbox type="text" name="Rate" placeholder={Rate} />
            {/* {ID === 1 ? null : (
              <FormLabelbox type="text" name="Rate" placeholder={Rate} />
            )} */}
          </td>
          <td role="cell" className="right">
            {ID === 1 ? null : (
              <FormInputBox
                type="text"
                IsCurrency={true}
                name="TaxAmount"
                placeholder={TaxAmount}
                value={handleCallback(setTaxAmount)}
                //   disabled={NotEditable}
                errorList={handleCallback(setErrorList)}
                schema={MoneySchema}
              />
            )}
          </td>
        </>
      )}
      <td role="cell" className="right">
        <FormLabelbox type="text" name="TotalDebit" placeholder={TotalDebit} />
      </td>
      <td role="cell" className="right">
        <FormLabelbox
          type="text"
          name="TotalCredit"
          placeholder={TotalCredit}
        />
      </td>
      <RemoveReceiptLine ID={ID} Row={RowContentList} />
    </tr>
  );
};

export default NewReceiptLine;
