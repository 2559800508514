import React, { useState, useCallback, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { FaRegEdit, FaSave, FaTimesCircle } from "react-icons/fa";
import { Row, Col, Form, Button } from "react-bootstrap";
import FormDropdown from "../Form/FormDropdown";
import FormTable from "../Form/FormTable";
import FormInputBox from "../Form/FormInputbox";
import FormRadio from "../Form/FormRadio";
import FormCheck from "../Form/FormCheck";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import apiError from "../../libs/apiError";
import GetDatetime from "../../libs/GetDatetime";
import { SyncEntities } from "./SyncEntities";
import { AppContext } from "../../libs/contextLib";
import OutputMessage from "../../components/OutputMessage";
import ListSort from "../../libs/ListSort";
import { handleValidation } from "../handleValidation";
import {
  LabelColumnAmount,
  InputColumnAmount,
  DataNotChanged,
  ValidationError,
  inputRegex,
  inputRegexMessage,
} from "../../libs/Variables";

const CompanyListDetails = (props) => {
  const context = useContext(AppContext);
  const location = useLocation();
  const params = location.state;

  const [notEditable, setnotEditable] = useState(false);
  const [EditVisible, setEditVisible] = useState(false);
  const [sectionElementIsHidden, setSectionElementIsHidden] = useState(true);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [PostFeedback, setPostFeedback] = useState(false);
  const [AddedSuccess, setAddedSuccess] = useState(false);

  const [ErrorList, setErrorList] = useState([]);
  const [ValidationErrorList, setValidationErrorList] = useState([]);
  const [CompanySettingID, setCompanySettingID] = useState();
  const [TaxSolutionsListID, setTaxSolutionsListID] = useState();
  const [ItemFilterName, setItemFilterName] = useState();
  const [ItemFilterValue, setItemFilterValue] = useState();
  const [OrderEntryTransactionDefinition, setOrderEntryTransactionDefinition] =
    useState();
  const [AttachmentFolderName, setAttachmentFolderName] = useState();
  const [AllowableBanks, setAllowableBanks] = useState();
  const [TaxSolutionsLists, setTaxSolutionsLists] = useState();
  const [JournalTypeLists, setJournalTypeLists] = useState();
  const [CompanyID, setCompanyID] = useState();
  const [SiteID, setSiteID] = useState();
  const [SiteSettingsID, setSiteSettingsID] = useState();
  const [PlaceholderListFilled, setPlaceholderListFilled] = useState();
  const [DataUpdated, setDataUpdated] = useState(false);
  const [NoUpdateRequired, setNoUpdateRequired] = useState(false);
  const [PermittedTaxImplications, setPermittedTaxImplications] = useState();
  const [TaxImplicationList, setTaxImplicationList] = useState();

  const [PostTopLevel, setPostTopLevel] = useState();
  const [ClassMandatory, setClassMandatory] = useState();
  const [DepartmentMandatory, setDepartmentMandatory] = useState();
  const [MemoMandatory, setMemoMandatory] = useState();
  const [UseAttachments, setUseAttachments] = useState();

  const ItemFilterNameSchema = yup
      .string()
      .nullable()
      .matches(inputRegex, inputRegexMessage)
      .max(100, "Must be less than 100 characters"),
    ItemFilterValueSchema = yup
      .string()
      .nullable()
      .matches(inputRegex, inputRegexMessage)
      .max(100, "Must be less than 100 characters"),
    OrderEntryTransactionDefinitionSchema = yup
      .string()
      .required("Required")
      .nullable()
      .matches(inputRegex, inputRegexMessage)
      .max(100, "Must be less than 100 characters"),
    AttachmentFolderNameSchema = yup
      .string()
      .required("Required")
      .nullable()
      .matches(inputRegex, inputRegexMessage)
      .max(100, "Must be less than 100 characters");

  if (!CompanyID) {
    setCompanyID(sessionStorage.getItem("CompanyID"));
  }
  if (!SiteID) {
    setSiteID(sessionStorage.getItem("siteID"));
  }
  if (!SiteSettingsID) {
    setSiteSettingsID(sessionStorage.getItem("SiteSettingsID"));
  }

  const loadData = useCallback(async () => {
    if (params !== undefined && params) {
      setUpdateSuccess(params.updateSuccess);
      setAddedSuccess(params.AddedSuccess);
      setPostFeedback(params.PostFeedback);
    }

    setnotEditable(true);
    setEditVisible(true);
    setSectionElementIsHidden(false);

    await trackPromise(
      Promise.all([
        axios
          .get("/api/SyscompanySettings/" + SiteID + "/" + CompanyID)
          .catch((e) => {
            var message = apiError("apiSyscompanySettings Get: ", e);
            setErrorList((ErrorList) => [...ErrorList, message]);
          }),
      ])
        .then((responses) => {
          let placeholderArr,
            AllowableBanks,
            TaxSolutionsLists,
            JournalTypeLists;
          // AccountGroupList;

          if (responses[0] && responses[0] !== undefined) {
            placeholderArr = responses[0].data[0];
            AllowableBanks = ListSort(placeholderArr.bankAccountList);
            JournalTypeLists = ListSort(placeholderArr.journalTypeLists);
            // AccountGroupList = ListSort(placeholderArr.accountGroupList);
            TaxSolutionsLists = placeholderArr.taxSolutionsList;
          } else {
            throw new Error("No Active Companies found");
          }

          setCompanySettingID(placeholderArr.companySettingID);
          setTaxSolutionsListID(placeholderArr.taxSolutionsListID);

          setPermittedTaxImplications(
            placeholderArr.permittedTaxImplications.split(",").map(Number)
          );
          setItemFilterName(placeholderArr.itemFilterName);
          setItemFilterValue(placeholderArr.itemFilterValue);
          setOrderEntryTransactionDefinition(
            placeholderArr.orderEntryTransactionDefinition
          );
          setTaxImplicationList(placeholderArr.taxImplicationList);

          setAllowableBanks(AllowableBanks);
          setTaxSolutionsLists(TaxSolutionsLists);
          setJournalTypeLists(JournalTypeLists);
          // AccountGroupList;

          // console.log(placeholderArr.permittedTaxImplications);
          // setTaxImplication(
          //   placeholderArr.permittedTaxImplications.split(",").map(Number)
          // );

          setPostTopLevel(placeholderArr.postTopLevel);
          setClassMandatory(placeholderArr.classMandatory);
          setDepartmentMandatory(placeholderArr.departmentMandatory);
          setMemoMandatory(placeholderArr.memoMandatory);
          setUseAttachments(placeholderArr.useAttachments);
          setAttachmentFolderName(placeholderArr.attachmentFolderName);

          setPlaceholderListFilled(true);
        })
        .catch((e) => {
          var message = apiError("api Placeholder Get:", e);
          setErrorList((ErrorList) => [...ErrorList, message]);
        })
    );
  }, [CompanyID, SiteID, params]);

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [loadData]);

  const handleCallback = (setter) => (name, theData) => {
    // console.log(name);
    // console.log(theData);
    const NoUpdateRequired = false,
      DataUpdated = true;

    var pattDataUpdated = new RegExp("DataUpdated"),
      pattAttachmentFolderName = new RegExp("AttachmentFolderName"),
      pattErrorList = new RegExp("errorList"),
      pattClearErrorList = new RegExp("ClearErrorList");
    if (pattErrorList.test(name)) {
      setValidationErrorList((errorList) => [...errorList, theData]);
    } else if (pattClearErrorList.test(name)) {
      if (name === "ClearValidationErrorList") {
        setValidationErrorList([]);
      } else {
        setErrorList(ErrorList.filter((item) => item !== theData));
      }
    } else if (pattAttachmentFolderName.test(name)) {
      var AttachmentFolderName = theData.trim();
      setter(AttachmentFolderName);
    } else if (pattDataUpdated.test(name)) {
      setNoUpdateRequired(NoUpdateRequired);
      setDataUpdated(DataUpdated);
    } else {
      setter(theData);
    }
    setNoUpdateRequired(NoUpdateRequired);
    setDataUpdated(DataUpdated);
  };

  const validateForm = () => {
    setValidationErrorList([]);
    return new Promise(async (resolve, reject) => {
      let items = [
        {
          name: "ItemFilterName",
          data: ItemFilterName,
          schema: ItemFilterNameSchema,
        },
        {
          name: "ItemFilterValue",
          data: ItemFilterValue,
          schema: ItemFilterValueSchema,
        },
        {
          name: "OrderEntryTransactionDefinition",
          data: OrderEntryTransactionDefinition,
          schema: OrderEntryTransactionDefinitionSchema,
        },
      ];
      if (UseAttachments) {
        items.push({
          name: "AttachmentFolderName",
          data: AttachmentFolderName,
          schema: AttachmentFolderNameSchema,
        });
      }

      var Status = [];

      items.forEach(async (element, i) => {
        Status[i] = false;
        await handleValidation(element)
          .then(() => {
            Status[i] = true;
          })
          .catch((e) => {
            setValidationErrorList((errorList) => [...errorList, e]);
            reject(e);
          });
        if (!Status.includes(false)) {
          resolve(true);
        }
      });
    });
  };

  const resetForm = useCallback(async () => {
    loadData();
  }, [loadData]);
  const handleFormReset = (e) => {
    e.preventDefault();
    resetForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateForm().then(() => {
      setErrorList("");
      doSubmit();
    });
  };

  const doSubmit = async () => {
    let DateTimeUpdated = GetDatetime();

    let outputJSON = {
      SiteID,
      BankAccountList: AllowableBanks,
      TaxSolutionsListID: TaxSolutionsListID,
      PermittedTaxImplications: PermittedTaxImplications.toString(),
      ItemFilterName: ItemFilterName,
      ItemFilterValue: ItemFilterValue,
      OrderEntryTransactionDefinition: OrderEntryTransactionDefinition,
      // AccountGroupList: output.AccountGroupList,
      JournalTypeLists: JournalTypeLists,

      PostTopLevel,
      ClassMandatory,
      DepartmentMandatory,
      MemoMandatory,
      UseAttachments,
      AttachmentFolderName,

      DateTimeUpdated: DateTimeUpdated,
    };

    // console.log("put");
    outputJSON["CompanySettingID"] = CompanySettingID;
    outputJSON["CompanyID"] = CompanyID;
    // console.log(outputJSON);

    if (DataUpdated) {
      await trackPromise(
        axios
          .put(
            "api/SyscompanySettings/" + CompanySettingID + "/" + CompanyID,
            outputJSON
          )
          .then((response) => {
            setUpdateSuccess(true);
            setAddedSuccess(false);
            setnotEditable(true);
            setEditVisible(true);
            resetForm();
          })
          .catch((e) => {
            apiError("PutAPI: ", e);
            var message = apiError("PutAPI: ", e);
            setErrorList((errorList) => [...errorList, message]);
          })
      );
    } else {
      setNoUpdateRequired(true);
    }
  };

  const handleSetEdit = () => {
    setnotEditable(false);
    setEditVisible(false);
    setUpdateSuccess(false);
    setAddedSuccess(false);
    setDataUpdated(false);
  };

  return (
    <div className="CompanyListDetails">
      <h1>Company Settings</h1>
      <Row>
        <div id="sectionOne"></div>
        <Col>
          {EditVisible &&
          (context.SettingsList.CanEdit.CompanySettings ||
            context.SettingsList.IsAdmin) ? (
            <div className="right TooltipActionButton">
              <Button variant="primary" type="button" onClick={handleSetEdit}>
                <FaRegEdit /> Edit
              </Button>
            </div>
          ) : null}
          <SyncEntities
            errorList={handleCallback(setErrorList)}
            PostFeedback={handleCallback(setPostFeedback)}
          />
        </Col>
      </Row>

      <OutputMessage
        errorList={ErrorList}
        ClearErrorList={handleCallback()}
        updateSuccess={updateSuccess}
        AddedSuccess={AddedSuccess}
        PostFeedback={PostFeedback}
      />

      {PlaceholderListFilled ? (
        <div className="detailsContent">
          <Form id="CompanyListDetailsForm" onReset={handleFormReset}>
            <div id="sectionTwo">
              <FormTable
                id="AllowableBanks"
                Label="Allowable Banks"
                name="AllowableBanks"
                itemContent={AllowableBanks}
                value={handleCallback(setAllowableBanks)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                required={false}
                disabled={notEditable}
                hidden={sectionElementIsHidden}
                errorList={handleCallback(setValidationErrorList)}
              />
              <FormTable
                id="JournalTypeLists"
                Label="Journal Types"
                name="JournalTypeLists"
                itemContent={JournalTypeLists}
                value={handleCallback(setJournalTypeLists)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                required={false}
                disabled={notEditable}
                hidden={sectionElementIsHidden}
                errorList={handleCallback(setValidationErrorList)}
              />
              <FormDropdown
                id="TaxSolutionsListID"
                Label="Tax Solution"
                name="TaxSolutionsListID"
                placeholder={TaxSolutionsListID}
                itemContent={TaxSolutionsLists}
                value={handleCallback(setTaxSolutionsListID)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                required={false}
                disabled={notEditable}
                hidden={sectionElementIsHidden}
                errorList={handleCallback(setValidationErrorList)}
              />

              <FormRadio
                MultiSelect={true}
                Label="Permitted Tax Implication"
                name="PermittedTaxImplications"
                id="PermittedTaxImplications"
                valueList={TaxImplicationList}
                initialState={PermittedTaxImplications}
                value={handleCallback(setPermittedTaxImplications)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditable}
                errorList={handleCallback(setValidationErrorList)}
              />
              <FormInputBox
                type="text"
                Label="Item Filter Name"
                name="ItemFilterName"
                placeholder={ItemFilterName}
                ConvertToUppercase="true"
                value={handleCallback(setItemFilterName)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditable}
                errorList={handleCallback(setValidationErrorList)}
                schema={ItemFilterNameSchema}
              />
              <FormInputBox
                type="text"
                Label="Item Filter Value"
                name="ItemFilterValue"
                placeholder={ItemFilterValue}
                value={handleCallback(setItemFilterValue)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditable}
                errorList={handleCallback(setValidationErrorList)}
                schema={ItemFilterValueSchema}
              />
              <FormInputBox
                type="text"
                Label="Order Entry Transaction Definition"
                name="OrderEntryTransactionDefinition"
                placeholder={OrderEntryTransactionDefinition}
                value={handleCallback(setOrderEntryTransactionDefinition)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditable}
                errorList={handleCallback(setValidationErrorList)}
                schema={OrderEntryTransactionDefinitionSchema}
              />

              {/* <FormTable
              id="AccountGroupList"
              Label="Account Groups"
              name="AccountGroupList"
              itemContent={AccountGroupList}
              value={handleCallback}
              LabelColumnAmount={LabelColumnAmount}
              InputColumnAmount={InputColumnAmount}
              required={false}
              disabled={notEditable}
              hidden={sectionElementIsHidden}
              errorList={handleCallback(setErrorList)}
            /> */}

              <FormCheck
                type="switch"
                Label="Class Mandatory?"
                name="ClassMandatory"
                id="ClassMandatory"
                initialState={ClassMandatory}
                value={handleCallback(setClassMandatory)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditable}
                errorList={handleCallback(setValidationErrorList)}
              />

              <FormCheck
                type="switch"
                Label="Department Mandatory?"
                name="DepartmentMandatory"
                id="DepartmentMandatory"
                initialState={DepartmentMandatory}
                value={handleCallback(setDepartmentMandatory)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditable}
                errorList={handleCallback(setValidationErrorList)}
              />

              <FormCheck
                type="switch"
                Label="Memo Mandatory?"
                name="MemoMandatory"
                id="MemoMandatory"
                initialState={MemoMandatory}
                value={handleCallback(setMemoMandatory)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditable}
                errorList={handleCallback(setValidationErrorList)}
              />

              <FormCheck
                type="switch"
                Label="Use Attachments?"
                name="UseAttachments"
                id="UseAttachments"
                initialState={UseAttachments}
                value={handleCallback(setUseAttachments)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditable}
                errorList={handleCallback(setValidationErrorList)}
              />

              {UseAttachments ? (
                <FormInputBox
                  type="text"
                  Label="Attachment Folder Name"
                  name="AttachmentFolderName"
                  placeholder={AttachmentFolderName}
                  value={handleCallback(setAttachmentFolderName)}
                  LabelColumnAmount={LabelColumnAmount}
                  InputColumnAmount={InputColumnAmount}
                  disabled={notEditable}
                  errorList={handleCallback(setValidationErrorList)}
                  schema={AttachmentFolderNameSchema}
                />
              ) : null}

              <FormCheck
                type="switch"
                Label="Post to Top Level?"
                name="PostTopLevel"
                id="PostTopLevel"
                initialState={PostTopLevel}
                value={handleCallback(setPostTopLevel)}
                LabelColumnAmount={LabelColumnAmount}
                InputColumnAmount={InputColumnAmount}
                disabled={notEditable}
                errorList={handleCallback(setValidationErrorList)}
              />

              {!EditVisible ? (
                <>
                  <div className="container FormSave">
                    <Row>
                      <Col sm={LabelColumnAmount}></Col>
                      <Col sm={8} className="IconBtn">
                        <Button
                          variant="primary"
                          type="button"
                          onClick={handleSubmit}
                          name="Submit"
                        >
                          <FaSave /> Save
                        </Button>
                        {NoUpdateRequired ? DataNotChanged : null}
                        {ValidationErrorList.length >= 1 ? (
                          <ValidationError
                            errorListState={ValidationErrorList}
                            ClearErrorList={handleCallback(
                              setValidationErrorList
                            )}
                          />
                        ) : null}

                        <Button variant="primary" onClick={handleFormReset}>
                          <FaTimesCircle /> Cancel
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : null}
            </div>
          </Form>
        </div>
      ) : null}
    </div>
  );
};
export default CompanyListDetails;
